import React from 'react';

interface LinkifyTextProps {
  text: string;
}

const LinkifyText: React.FC<LinkifyTextProps> = ({ text }) => {
  // Regex patterns for URLs and phone numbers
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const phoneRegex = /(\+?\d{1,4}[-.\s]?\(?\d{1,3}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9})/g;
  const currentDomain = window.location.hostname;

  const renderTextWithLinks = () => {
    if (!text) return '';

    // First split by URLs
    let parts = text.split(urlRegex);
    let urlMatches = text.match(urlRegex) || [];
    let result: React.ReactNode[] = [];
    let urlMatchIndex = 0;

    parts.forEach((part, index) => {
      if (urlMatches[urlMatchIndex] === part) {
        // Handle URL
        const url = new URL(part);
        const isExternalLink = url.hostname !== currentDomain;
        result.push(
          <a
            key={`url-${index}`}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => {
              if (isExternalLink) {
                const confirm = window.confirm('This link will take you to an external website. Do you want to continue?');
                if (!confirm) {
                  e.preventDefault();
                }
              }
            }}
            className="text-brand-lightblue hover:text-brand-lightblue/80 underline"
          >
            {part}
          </a>
        );
        urlMatchIndex++;
      } else {
        // Split remaining text by phone numbers
        const phoneParts = part.split(phoneRegex);
        const phoneMatches = part.match(phoneRegex) || [];
        let phoneMatchIndex = 0;

        phoneParts.forEach((phonePart, phoneIndex) => {
          if (phoneMatches[phoneMatchIndex] === phonePart) {
            // Handle phone number
            const formattedPhone = phonePart.replace(/[^\d+]/g, '');
            result.push(
              <a
                key={`phone-${index}-${phoneIndex}`}
                href={`tel:${formattedPhone}`}
                className="text-brand-lightblue hover:text-brand-lightblue/80 underline"
                onClick={(e) => {
                  const confirm = window.confirm('Do you want to call this number?');
                  if (!confirm) {
                    e.preventDefault();
                  }
                }}
              >
                {phonePart}
              </a>
            );
            phoneMatchIndex++;
          } else if (phonePart) {
            // Regular text
            result.push(phonePart);
          }
        });
      }
    });

    return result;
  };

  return <p className="whitespace-pre-line overflow-wrap-anywhere">{renderTextWithLinks()}</p>;
};

export default LinkifyText;