import React, { useState, useEffect, useRef } from 'react';
import { ExternalLink, Plus, Search, Upload, X } from 'lucide-react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useDebounce } from 'use-debounce';
import QuerySrvc from '../../services/queries/QuerySrvc';
import ModalWrapper from '../Modal';
import SaveAnimation from '../SaveAnimation';
import { ASPECT_RATIO, ASPECT_RATIO_CLASS_MAP } from '../../config/constants';
import { getOrientation } from '../../services/utils';

interface ImageSearchDrawerProps {
  onFileUpload: (e: any) => void;
  suggestedSearch?: string;
  onImageSelect?: (image: any) => void;
  subtitle?: string
  open: boolean;
  aspectRatio?: typeof ASPECT_RATIO[keyof typeof ASPECT_RATIO],
  disabled?: boolean;
}

const ImageSearch: React.FC<ImageSearchDrawerProps> = ({ open, disabled, subtitle, onFileUpload, suggestedSearch = '',
  onImageSelect, aspectRatio = ASPECT_RATIO.VIDEO }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedQuery] = useDebounce(searchQuery, 500);
  const [selectedImage, setSelectedImage] = useState<any>(null);

  const { data: images, refetch: doUnsplashSearch, isError, error,
    isLoading, isFetching, isSuccess } = QuerySrvc.QUERIES.MEDIA.SEARCH(debouncedQuery || suggestedSearch.trim(), undefined, undefined, getOrientation(aspectRatio));

  const modalRef: any = useRef();

  const openModal = () => {
    modalRef?.current?.openModal();
  };

  const closeModal = () => {
    modalRef?.current?.closeModal();
  };

  useEffect(() => {
    if (selectedImage) {
      onImageSelect && onImageSelect(selectedImage);      
    }    
  }, [selectedImage]);

  useEffect(() => {
    if (open) {
      openModal();
    } else {
      closeModal();
    }
  }, [open]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="flex flex-col h-full bg-brand-darkgray relative">
      {
        subtitle &&
        <div className="flex flex-row mb-6 text-lg font-normal text-brand-lightgraybackup">
          <div className="flex text-lg">
            {subtitle}
          </div>
        </div>
      }

      <div className="relative mb-6 px-2">
        <button
          disabled={disabled}
          onClick={onFileUpload}
          className={`flex items-center justify-center gap-4 text-brand-lightgray py-2 rounded w-full border-brand-fadegray border-2 
                      hover:shadow-lightgray transition-all bg-gradient-to-r from-brand-darkgray via-brand-lightgray/10 to-brand-darkgray`}
        >
          <Upload className='w-4 h-4' /> Upload Image
        </button>
      </div>

      <div className="relative mb-6 px-2">
        <input
          disabled={disabled}
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search images..."
          className="w-full px-2 py-2 pl-10 bg-brand-fadegray/20 border border-brand-fadegray rounded text-brand-lightgray focus:outline-none focus:ring-2 focus:ring-brand-lightgray"
        />
        <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 w-5 h-5 text-brand-lightgray" />
      </div>

      {isLoading && (
        <div className="flex items-center justify-center flex-grow">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-brand-lightgray"></div>
        </div>
      )}

      {error && (
        <div className="text-brand-danger text-center p-4 flex-grow">
          Failed to load images. Please try again.
        </div>
      )}

      {!isLoading && !error && (
        <div className="flex-grow overflow-y-auto custom-scroll p-2">
          {/* {
            images && images.length > 0 ?
              <div className="col-span-2 text-brand-lightgray text-right font-bold py-1">
                Photos By <a className="underline" target='_blank' href="https://unsplash.com/?utm_source=CarbonPaper&utm_medium=referral">Unsplash</a>
              </div> : null
          } */}
          <div className="grid grid-col-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2 gap-4">
            {images && images.length > 0 ? (
              images.map((image: any, index: number) => {
                return (
                  <div
                    key={image.photoId}
                    className={`group ${ASPECT_RATIO_CLASS_MAP[aspectRatio]} ${disabled && image.photoId !==selectedImage?.photoId ? '': 'cursor-pointer'} relative rounded overflow-hidden bg-brand-fadegray/20`}
                    onClick={() => setSelectedImage(image)}
                  >
                    <img
                      src={image?.urls?.small || image?.urls?.medium || image?.urls?.large || image?.urls?.raw}
                      className={`${ASPECT_RATIO_CLASS_MAP[aspectRatio]} w-full object-cover transition-transform ${disabled && image.photoId !==selectedImage?.photoId ? 'blur-sm' : 'group-hover:scale-105'}`}
                      loading="lazy"
                    />
                    <div className="absolute inset-0 bg-black/0 group-hover:bg-black/20 transition-colors cursor-pointer" />
                    {image.attribution?.profileUrl && (
                      <div className="absolute hidden group-hover:flex bottom-0 left-0 right-0 drop-shadow-contrast-black p-2 text-brand-lightgray text-sm justify-end">
                        {
                          image.attribution?.profileUrl ? 
                          <a href={`${image.attribution?.profileUrl}?utm_source=carbonpaper&utm_medium=referral`} target="_blank" rel="noopener noreferrer"
                            className="underline flex cursor-default" onClick={(e) => e.stopPropagation()} >
                            {image.attribution?.name} <ExternalLink size={14} className='ml-2'/>
                          </a> :
                          <span>{image.attribution?.name}</span>
                        }
                      </div>
                    )}                  
                  </div>
                )
              })
            ) : (
              <div className="col-span-2 text-brand-lightgray text-center p-4">
                {debouncedQuery ? 'No images found' : 'Enter a search term to find images'}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageSearch;