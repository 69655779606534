import { MUTATIONS as AUTH_MUTATIONS, QUERIES as AUTH_QUERIES } from "./AuthApi";
import { MUTATIONS as RSVP_MUTATIONS, QUERIES as RSVP_QUERIES } from "./RsvpApi";
import { MUTATIONS as OPEN_MUTATIONS, QUERIES as OPEN_QUERIES } from "./OpenApi";
import { MUTATIONS as PROGRAM_MUTATIONS } from "./ProgramApi";
import { MUTATIONS as ACTIVITY_MUTATIONS, QUERIES as ACTIVITY_QUERIES } from "./ActivityApi";
import { MUTATIONS as EVENT_MUTATIONS, QUERIES as EVENT_QUERIES } from "./EventApi";
import { MUTATIONS as FEED_MUTATIONS, QUERIES as FEED_QUERIES } from "./FeedApis";
import { MUTATIONS as DOCS_MUTATIONS, QUERIES as DOCS_QUERIES } from "./DocsApi";
import { MUTATIONS as FEEDBACK_MUTATIONS, QUERIES as FEEDBACK_QUERIES } from "./FeedbackFormApi";
import { MUTATIONS as ITINERARY_MUTATIONS, QUERIES as ITINERARY_QUERIES  } from "./ItineraryApi";
import { MUTATIONS as GOOGLE_MUTATIONS, QUERIES as GOOGLE_QUERIES  } from "./GoogleApi";
import { MUTATIONS as BULLETIN_MUTATIONS, QUERIES as BULLETIN_QUERIES  } from "./BulletinApi";
import { MUTATIONS as MEDIA_MUTATIONS, QUERIES as MEDIA_QUERIES  } from "./MediaApi";


export default {
  QUERIES: {
    AUTH: AUTH_QUERIES,
    RSVP: RSVP_QUERIES,
    OPEN: OPEN_QUERIES,
    EVENT: EVENT_QUERIES,
    ACTIVITY: ACTIVITY_QUERIES,
    FEED: FEED_QUERIES,
    DOCS: DOCS_QUERIES,
    FEEDBACK: FEEDBACK_QUERIES,
    ITINERARY: ITINERARY_QUERIES,
    GOOGLE: GOOGLE_QUERIES,
    BULLETIN: BULLETIN_QUERIES,
    MEDIA: MEDIA_QUERIES,
  },

  MUTATIONS: {
    AUTH: AUTH_MUTATIONS,
    RSVP: RSVP_MUTATIONS,
    OPEN: OPEN_MUTATIONS,
    PROGRAM: PROGRAM_MUTATIONS,
    ACTIVITY: ACTIVITY_MUTATIONS,
    EVENT: EVENT_MUTATIONS,
    FEED: FEED_MUTATIONS,
    DOCS: DOCS_MUTATIONS,
    FEEDBACK: FEEDBACK_MUTATIONS,
    ITINERARY: ITINERARY_MUTATIONS,
    GOOGLE: GOOGLE_MUTATIONS,
    BULLETIN: BULLETIN_MUTATIONS,
    MEDIA: MEDIA_MUTATIONS,
  }
};