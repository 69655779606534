export const EMAIL_REGEX = new RegExp("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,7}$");

export const MOBILE_PHONE_REGEX = new RegExp("^[1-9]{1}[0-9]{9}$");

export const MOBILE_OR_EMAIL_REGEX = new RegExp(EMAIL_REGEX.source + "|" + MOBILE_PHONE_REGEX.source);

export const GOOGLE_SIGNIN_SRC = "https://accounts.google.com/gsi/client";

export const GOOGLE_MAPS_SRC = "https://maps.googleapis.com/maps/api/js?key=" + ENV_CONSTANTS.GOOGLE_MAPS_KEY + "&loading=async&libraries=places";

export const OG_META = {
  TITLE: "CarbonPaper",
  DESCRIPTION: "All things events. Create, Share, Engage!",
  URL: ENV_CONSTANTS.BASE_URL,
  IMAGE: "https://cdn.carbonpaper.app/static_content/meta-og-logo.jpg",
};

export enum SOURCE {
  UNSPLASH = "UNSPLASH",
  PEXELS = "PEXELS",
  LINK = "LINK"
}

export enum ORIENTATION_TYPES {
  'LANDSCAPE' = 'landscape',
  'PORTRAIT' = 'portrait',
  'SQUARISH' = 'squarish'
}

export const ASPECT_RATIO = {
  SQUARE: 1,
  VIDEO: 16/9,
  PORTRAIT: 9/16
}

export const ASPECT_RATIO_CLASS_MAP = {
  [ASPECT_RATIO.SQUARE]: 'aspect-square',
  [ASPECT_RATIO.VIDEO]: 'aspect-video',
  [ASPECT_RATIO.PORTRAIT]: 'aspect-16/9'
}

export default {
  ASPECT_RATIO_CLASS_MAP,
  ORIENTATION_TYPES,
  ASPECT_RATIO,
  SOURCE,
  EMAIL_REGEX,
  MOBILE_PHONE_REGEX,
  MOBILE_OR_EMAIL_REGEX,
  ENV_CONSTANTS,
  GOOGLE_SIGNIN_SRC,
  GOOGLE_MAPS_SRC,
  OG_META
}