import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import HttpSrvc from '../HttpSrvc';
import { deleteFromQueryList } from './QueryHelper';


export const QUERIES = {
  GET_BULLETINS_FOR_EVENT: (eventId: string) => useQuery({
    queryKey: [BULLETIN_CONSTANTS.QUERY_KEYS.BULLETINS, eventId],
    queryFn: async () => {
      return HttpSrvc.get(`${BULLETIN_CONSTANTS.BULLETIN_API}/${eventId}`);
    },
    select: (data) => {
      return data.sort((a: any, b: any) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
    },
    enabled: !!eventId
  })
};


export const MUTATIONS = {
  POST: (queryClient?: QueryClient) => useMutation({
    mutationFn: ({ formData, eventId }: any) => {
      return HttpSrvc.postFormData(`${BULLETIN_CONSTANTS.BULLETIN_API}/${eventId}`, formData)
    },
    onSuccess: (response, variables) => {
      queryClient?.invalidateQueries({ queryKey: [BULLETIN_CONSTANTS.QUERY_KEYS.BULLETINS, variables.eventId] });
    }
  }),
  DELETE: (queryClient?: QueryClient) => useMutation({
    mutationFn: ({ eventId, bulletinId }: any) => {
      return HttpSrvc.delete(`${BULLETIN_CONSTANTS.BULLETIN_API}/${eventId}/${bulletinId}`);
    },
    onMutate: (variables) => {
      queryClient?.cancelQueries({ queryKey: [BULLETIN_CONSTANTS.QUERY_KEYS.BULLETINS, variables.eventId] });
    },
    onSuccess: (data, variables) => {
      queryClient && deleteFromQueryList(queryClient, [BULLETIN_CONSTANTS.QUERY_KEYS.BULLETINS, variables.eventId], variables.bulletinId);
    }
  })
}

export const BULLETIN_CONSTANTS = {
  QUERY_KEYS: {
    BULLETINS: 'BULLETINS'
  },
  BULLETIN_API: '/api/bulletin'
}