import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import ApiService from '../../utils/api';
import FeedCard from './FeedCard';
import { Feed, PaginatedResponse } from './types';
import { CreatePost } from './CreatePost';
import QuerySrvc from '../../services/queries/QuerySrvc';

interface EventFeedsProps {
  eventId: string;
}

const EventFeeds = ({ eventId }: EventFeedsProps) => {
  const containerRef = useRef(null);

  const queryClient = useQueryClient();

  const feedDeleteMutation = QuerySrvc.MUTATIONS.FEED.FEEDS.DELETE(queryClient);

  const feedLikeMutation = QuerySrvc.MUTATIONS.FEED.FEEDS.LIKE(queryClient);

  const addCommentMutation = QuerySrvc.MUTATIONS.FEED.COMMENTS.POST(queryClient);

  const { data: feedsResponse, isError: isFeedsError, isLoading, isSuccess: isFeedsSuccess, fetchNextPage: fetchNextFeedsPage,
    isFetching: isFetchingFeeds, refetch: feedsRefetch, error } = QuerySrvc.QUERIES.FEED.FEEDS.GET(eventId, 10);

  const { data: event } = QuerySrvc.QUERIES.EVENT.EVENT_GET_BY_ID(eventId) as any;

  const isParticipant = event?.isOwner || event?.isHost || event?.isGuest;

  const [feeds, setFeeds] = useState<any[]>([]);

  const handleScroll = useCallback(() => {

    const container: any = containerRef.current;
    if (container && (container.scrollHeight - container.scrollTop) <= (container.clientHeight + 50) && !isFetchingFeeds && feedsResponse.page < feedsResponse.totalPages) {  
      fetchNextFeedsPage();
    }
    return;
  }, [isFetchingFeeds, fetchNextFeedsPage]);

  useEffect(() => {
    const container: any = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  const handleLike = (feedId: string, like: boolean) => {
    feedLikeMutation.mutate({eventId, feedId, like});  
  };

  const handleComment = (feedId: string, comment: string) => {
    if (comment) {
      addCommentMutation.mutate({ eventId: event.id, feedId, comment });
     
    }
  };

  const handleFeedDelete = (feedId: string) => {    
    feedDeleteMutation.mutate({eventId, feedId});
  }

  if (isLoading) return (
    <div className="flex flex-1 justify-center items-center py-8">
      <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-brand-lightgray"></div>
    </div>
  );

  if (error) return (
    <div className="text-center py-8 text-brand-lightgray">
      Error loading feeds
    </div>
  );

  return (
    <div className="flex flex-1 flex-col overflow-y-auto custom-scroll" ref={containerRef} >
      <div className="relative flex flex-1 flex-col w-full sm:max-w-2xl sm:min-w-[640px] mx-auto py-8 px-4" >

        {isParticipant && <CreatePost eventId={eventId} /> || null}

        {!feedsResponse?.feeds?.length ? (
          <div className="text-center py-8 text-brand-lightgray flex flex-1 justify-center items-center">
            No feeds available yet
          </div>
        ) : (<div className="flex flex-1 flex-col space-y-6 pb-10">
          {feedsResponse.feeds.map((feed: any) => (
            <FeedCard
              key={feed.id}
              feed={feed}
              onLike={handleLike}
              onComment={handleComment} 
              onDelete={handleFeedDelete}
              />
          ))}
        </div>)}
      </div>
    </div>
  );
};

export default EventFeeds;