import { QueryClient, QueryKey } from "@tanstack/react-query"

const updateInifiniteQueryList = (queryClient: QueryClient, queryKey: QueryKey, listKey: string, object: any) => {
  queryClient?.setQueryData(queryKey, (oldData: any) => {
    const clone = { ...oldData };
    clone.pages = clone.pages.map(((page: any) => {
      return {
        ...page,
        [listKey]: page[listKey]?.map((item: any) => {
          if (item.id === object.id) {
            return object;
          }
          return item;
        }) || []
      }
    }))
    return clone;
  });
}

const deleteFromQueryList = (queryClient: QueryClient, queryKey: QueryKey, id: string) => {
  queryClient?.setQueryData(queryKey, (oldData: any) => {
    const clone = [ ...oldData ];
    const index = clone.findIndex((item: any) => item.id === id);
    if (index !== -1) {
      clone.splice(index, 1);
    }
    return clone;
  });
};



export { updateInifiniteQueryList, deleteFromQueryList }