import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import HttpSrvc from '../HttpSrvc';
import StorageSrvc from '../StorageSrvc';
import { STORAGE_SRVC_KEYS } from '../../config/constants/AppStrings';

export const QUERIES = {
  GET_FEEDBACK_FORMS: (eventId: string) => useQuery({
    queryKey: [FEEDBACK_CONSTANTS.QUERY_KEYS.FEEDBACK_FORMS, eventId],
    queryFn: () => {
      return HttpSrvc.get(`${FEEDBACK_CONSTANTS.FEEDBACK_FORM_BASE_API}/${eventId}/${FEEDBACK_CONSTANTS.FEEDBACK_FORM_API_SUFFIX}`);
    }
  }),

  GET_FEEDBACK_FORM_BY_ID: (eventId: string, formId: string) => useQuery({
    queryKey: [FEEDBACK_CONSTANTS.QUERY_KEYS.FEEDBACK_FORM, eventId, formId],
    queryFn: () => {
      return HttpSrvc.get(`${FEEDBACK_CONSTANTS.FEEDBACK_FORM_BASE_API}/${eventId}/${FEEDBACK_CONSTANTS.FEEDBACK_FORM_API_SUFFIX}/${formId}`);
    }
  }),
  GET_ACTIVE_FEEDBACK_FORM: (eventId: string) => useQuery({
    queryKey: [FEEDBACK_CONSTANTS.QUERY_KEYS.FEEDBACK_FORM, eventId, 'active'],
    queryFn: () => {
      return HttpSrvc.get(`${FEEDBACK_CONSTANTS.FEEDBACK_FORM_BASE_API}/${eventId}/${FEEDBACK_CONSTANTS.FEEDBACK_FORM_API_SUFFIX}/active`);
    }
  }),
  GET_FEEDBACK_FORM_RESPONSES: (eventId: string, formId: string) => useQuery({
    queryKey: [FEEDBACK_CONSTANTS.QUERY_KEYS.FEEDBACK_FORM_RESPONSE, eventId, formId],
    queryFn: () => {
      return HttpSrvc.get(`${FEEDBACK_CONSTANTS.FEEDBACK_FORM_BASE_API}/${eventId}/${FEEDBACK_CONSTANTS.FEEDBACK_FORM_API_SUFFIX}/${formId}/response`);
    },
    enabled: !!formId
  }),
}

export const MUTATIONS = {
  CREATE_FEEDBACK_FORM: (queryClient?: QueryClient) => useMutation({
    mutationFn: ({ eventId, currentForm: payload }: any) => {
      return HttpSrvc.post(`${FEEDBACK_CONSTANTS.FEEDBACK_FORM_BASE_API}/${eventId}/${FEEDBACK_CONSTANTS.FEEDBACK_FORM_API_SUFFIX}`, payload)
    },
    onSuccess: () => {
      queryClient?.invalidateQueries({ queryKey: [FEEDBACK_CONSTANTS.QUERY_KEYS.FEEDBACK_FORMS] });
    }
  }),

  UPDATE_FEEDBACK_FORM: (queryClient?: QueryClient) => useMutation({
    mutationFn: ({ eventId, formId, update }: any) => {
      return HttpSrvc.put(`${FEEDBACK_CONSTANTS.FEEDBACK_FORM_BASE_API}/${eventId}/${FEEDBACK_CONSTANTS.FEEDBACK_FORM_API_SUFFIX}/${formId}`, update)
    },
    onSuccess: () => {
      queryClient?.invalidateQueries({ queryKey: [FEEDBACK_CONSTANTS.QUERY_KEYS.FEEDBACK_FORMS] });
    }
  }),

  DELETE_FEEDBACK_FORM: (queryClient?: QueryClient) => useMutation({
    mutationFn: ({ eventId, formId }: any) => {
      return HttpSrvc.delete(`${FEEDBACK_CONSTANTS.FEEDBACK_FORM_BASE_API}/${eventId}/${FEEDBACK_CONSTANTS.FEEDBACK_FORM_API_SUFFIX}/${formId}`)
    },
    onSuccess: (data, variables) => {
      queryClient?.invalidateQueries({ queryKey: [FEEDBACK_CONSTANTS.QUERY_KEYS.FEEDBACK_FORMS, variables.eventId] });
    }
  })
}

export const FEEDBACK_CONSTANTS = {
  QUERY_KEYS: {
    FEEDBACK_FORMS: 'FEEDBACK_FORMS',
    FEEDBACK_FORM: 'FEEDBACK_FORM',
    FEEDBACK_FORM_RESPONSE: 'FEEDBACK_FORM_RESPONSE',
  },
  GET_FEEDBACK_FORMS_API: ``,
  FEEDBACK_FORM_BASE_API: `/api/events`,
  FEEDBACK_FORM_API_SUFFIX: `form`,
}
