import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import HttpSrvc from '../HttpSrvc';
import { ITINERAY_CONSTANTS } from './ItineraryApi';
import { SOURCE } from '../../config/constants';
import { DOCS_CONSTANTS } from './DocsApi';

export const QUERIES = {
  GET_BY_ID: (eventId: string, dayId: string, activityId: string) => useQuery({
    queryKey: [ACTIVITY_CONSTANTS.QUERY_KEYS.ACTIVITY, eventId, dayId, activityId],
    queryFn: () => {
      return HttpSrvc.get(`/api/events/${eventId}/programs/${dayId}/activity/${activityId}`);
    },
    staleTime: 2000,
    enabled: !!eventId
  })
};

export const MUTATIONS = {
  UPLOAD_DOC_AND_UPDATE_ACTIVITY: (queryClient?: QueryClient) => useMutation({
    mutationFn: async ({ eventId, formData, dayId, activityId, existingDocuments }: {
      eventId: string;
      dayId: string;
      activityId: string;
      existingDocuments: string[];
      formData: FormData
    }) => {
      const url = `${DOCS_CONSTANTS.DOCS_API}/event/${eventId}/common`
      const doc = await HttpSrvc.post(url, formData);
      const newDocIds = doc.map((d: any) => d.id);
      const payload = {
        documents: Array.from(new Set([...existingDocuments, ...newDocIds]))
      };
      const updatedActivity = await HttpSrvc.put(`/api/events/${eventId}/programs/${dayId}/activity/${activityId}`, payload);
      return updatedActivity;
    },
    onSuccess: (data: any, variables) => {
      queryClient?.invalidateQueries({ queryKey: [ACTIVITY_CONSTANTS.QUERY_KEYS.ACTIVITY, variables.eventId, variables.dayId, variables.activityId] });
    }

  }),
  CREATE_ACTIVITY: (queryClient?: QueryClient) => useMutation({
    mutationFn: ({ eventId, dayId, formData }: {
      eventId: string;
      dayId: string;
      formData: FormData
    }) => {
      return HttpSrvc.postFormData(`/api/events/${eventId}/programs/${dayId}/activity`, formData);
    },
    onSuccess: (data: any, variables) => {
      queryClient?.invalidateQueries({ queryKey: [ITINERAY_CONSTANTS.QUERY_KEYS.ITINERARY_DATA, variables.eventId] });
    }
  }),

  UPDATE_ACTIVITY: (queryClient?: QueryClient) => useMutation({
    mutationFn: ({ eventId, dayId, activityId, payload }: {
      eventId: string;
      dayId: string;
      activityId: string;
      payload: any
    }) => {
      return HttpSrvc.put(`/api/events/${eventId}/programs/${dayId}/activity/${activityId}`, payload);
    },
    onSuccess: (data: any, variables: any) => {
      queryClient?.invalidateQueries({ queryKey: [ITINERAY_CONSTANTS.QUERY_KEYS.ITINERARY_DATA, variables.eventId] });
      queryClient?.invalidateQueries({ queryKey: [ACTIVITY_CONSTANTS.QUERY_KEYS.ACTIVITY, variables.eventId, variables.dayId, variables.activityId] });

    }
  }),

  UPLOAD_ACTIVITY_IMAGE: (queryClient?: QueryClient) => useMutation({
    mutationFn: ({ eventId, dayId, activityId, file }: {
      eventId: string;
      dayId: string;
      activityId: string;
      file: File
    }) => {
      const formData = new FormData();
      formData.append('file', file);
      return HttpSrvc.postFormData(`/api/events/${eventId}/programs/${dayId}/activity/${activityId}/image`, formData);
    },
    onSuccess: (data: any, variables: any) => {
      queryClient?.invalidateQueries({ queryKey: [ITINERAY_CONSTANTS.QUERY_KEYS.ITINERARY_DATA, variables.eventId] });
    }
  }),

  UPLOAD_EXTERNAL_IMAGE: (queryClient?: QueryClient) => useMutation({
    mutationFn: ({ eventId, dayId, activityId, photoId, source }: {
      eventId: string;
      dayId: string;
      activityId: string;
      photoId: string,
      source: SOURCE
    }) => {
      return HttpSrvc.post(`/api/events/${eventId}/programs/${dayId}/activity/${activityId}/image/external`, { photoId, source });
    },
    onSuccess: (data: any, variables: any) => {
      queryClient?.invalidateQueries({ queryKey: [ITINERAY_CONSTANTS.QUERY_KEYS.ITINERARY_DATA, variables.eventId] });
    }
  }),

  DELETE_ACTIVITY: (queryClient?: QueryClient) => useMutation({
    mutationFn: ({ eventId, dayId, activityId }: {
      eventId: string;
      dayId: string;
      activityId: string
    }) => {
      return HttpSrvc.delete(`/api/events/${eventId}/programs/${dayId}/activity/${activityId}`);
    },
    onSuccess: (data: any, variables: any) => {
      queryClient?.invalidateQueries({ queryKey: [ITINERAY_CONSTANTS.QUERY_KEYS.ITINERARY_DATA, variables.eventId] });
    }
  })
}


export const ACTIVITY_CONSTANTS = {
  QUERY_KEYS: {
    ACTIVITY: 'ACTIVITY'
  }
}