import React from 'react';
import { Calendar, Clock, CheckCircle, Plus } from 'lucide-react';
import FloatingActionButton from '../FloatingActionButton';

type EventStatus = 'ongoing' | 'upcoming' | 'finished';

interface EventStatusTabsProps {
  activeStatus: EventStatus;
  onStatusChange: (status: EventStatus) => void;
  counts: {
    ongoing: number;
    upcoming: number;
    finished: number;
  };
  onFabClick?: () => void;
  fabVisible?: boolean;
  fabPulse?: boolean;
}

const EventStatusTabs: React.FC<EventStatusTabsProps> = ({ 
  activeStatus, 
  onStatusChange,
  counts,
  onFabClick,
  fabVisible,
  fabPulse = false
}) => {
  const tabs = [
    { 
      id: 'ongoing' as EventStatus, 
      label: 'Ongoing', 
      icon: Clock,
      count: counts.ongoing 
    },
    { 
      id: 'upcoming' as EventStatus, 
      label: 'Upcoming', 
      icon: Calendar,
      count: counts.upcoming 
    },
    { 
      id: 'finished' as EventStatus, 
      label: 'Finished', 
      icon: CheckCircle,
      count: counts.finished 
    }
  ];

  return (
   <>
    <div className="flex space-x-4 bg-brand-darkgray z-40 ">
      {tabs.map(tab => {
        const Icon = tab.icon;
        return (
          <button
            key={tab.id}
            onClick={() => onStatusChange(tab.id)}
            className={`
            flex items-center px-4 py-2 transition-colors ${
              activeStatus === tab.id
                ? 'text-brand-lightgray border-b'
                : 'text-brand-lightgraybackup hover:text-brand-lightgray hover:bg-brand-lightgray/5'
            }
            `}
          >
            <Icon className="w-4 h-4 mr-2 hidden sm:flex" />
            <span className="ml-2 bg-brand-fadegray/20 px-2 py-0.5 rounded-full text-sm">{tab.label}</span>
            <span className="ml-2 bg-brand-fadegray/20 px-2 py-0.5 rounded-full text-sm">
              {tab.count}
            </span>
          </button>
        );
      })}
    </div>
    <button
                type="button"
                onClick={() => onFabClick && onFabClick()}
                className="dark-btn flex relative"
              >
                <Plus className='w-4 h-4'/>Event
              </button>
    {/* <FloatingActionButton
        onClick={() => {
          onFabClick && onFabClick();
        }}
        visible={!!fabVisible}
        className={`relative ${!!fabVisible ? 'sm:flex hidden': '' }  bottom-0 right-0`}
        text='Event'
        pulse={fabPulse}
      /> */}
    </>
  );
};

export default EventStatusTabs;