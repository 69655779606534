import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import HttpSrvc from '../HttpSrvc';
import { ORIENTATION_TYPES } from '../../config/constants';

export const QUERIES = {
  SEARCH: (query: string, page: number = 1, limit: number = 30, orientation: ORIENTATION_TYPES = ORIENTATION_TYPES.LANDSCAPE) => useQuery({
    queryKey: [MEDIA_CONSTANTS.QUERY_KEYS.MEDIA, query],
    queryFn: () => {
      return query ? HttpSrvc.get(`${MEDIA_CONSTANTS.MEDIA_V2_API}${MEDIA_CONSTANTS.SEARCH}`, {
        query,
        page,
        limit,
        orientation
      }) : [];
    },
    staleTime: 30000,
    select: (data) => {
      return data || [];
    },
    enabled: !!query    
  }),
  GET_BY_ID: (mediaId?: string) => useQuery({
    queryKey: [MEDIA_CONSTANTS.QUERY_KEYS.MEDIA, mediaId],
    queryFn: () => {
      return HttpSrvc.get(`${MEDIA_CONSTANTS.MEDIA_V2_API}${MEDIA_CONSTANTS.SEARCH}`);
    }
  })
}

export const MUTATIONS = {};

export const MEDIA_CONSTANTS = {
  QUERY_KEYS: {
    MEDIA: 'MEDIA'
  },
  MEDIA_V2_API: '/api/v2/media',
  SEARCH: '/search',
  UNSPLASH_SEARCH: '/unsplash/search',
  PEXELS_SEARCH: '/pexels/search',
  
}