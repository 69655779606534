import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import HttpSrvc from '../HttpSrvc';

export const QUERIES = {
  GET_EVENT_DOCUMENTS_FOR_USER: (eventId: string, userId: string) => useQuery({
    queryKey: [DOCS_CONSTANTS.QUERY_KEYS.EVENT_DOCUMENTS, eventId, userId],
    queryFn: () => {
      return HttpSrvc.get(`${DOCS_CONSTANTS.DOCS_API}/event/${eventId}/user/${userId}`);
    },
    enabled: !!eventId && !!userId
  })
};

export const MUTATIONS = {
  HOST_DOCUMENT_UPLOAD_FOR_GUEST: (queryClient: QueryClient) => useMutation({
    mutationFn: ({ eventId,
      userId,
      formData,
      isCommonDoc }: {
        eventId: string;
        userId: string;
        formData: FormData;
        isCommonDoc?: boolean;
      }) => {
      const url = isCommonDoc ?
        `${DOCS_CONSTANTS.DOCS_API}/event/${eventId}/common` :
        `${DOCS_CONSTANTS.DOCS_API}/event/${eventId}/user/${userId}`;
      return HttpSrvc.post(url, formData);;
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: [DOCS_CONSTANTS.QUERY_KEYS.EVENT_DOCUMENTS, variables.eventId, variables.userId] });
    }
  }),
  DELETE_DOCUMENT_MUTATION: (queryClient: QueryClient) => useMutation({
    mutationFn: ({ eventId, userId, documentId }: { eventId: string, userId: string, documentId: string }) => {
      return HttpSrvc.delete(`${DOCS_CONSTANTS.DOCS_API}/${documentId}`);;
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: [DOCS_CONSTANTS.QUERY_KEYS.EVENT_DOCUMENTS, variables.eventId, variables.userId || ''] });
    }
  })
};

export const DOCS_CONSTANTS = {
  QUERY_KEYS: {
    EVENT_DOCUMENTS: 'EVENT_DOCUMENTS'
  },
  DOCS_API: '/api/documents'
};