import React, { useState, useEffect, useRef } from 'react';
import { MapPin, Loader2 } from 'lucide-react';
import { useDebounce } from 'use-debounce';
import QuerySrvc from '../services/queries/QuerySrvc';


interface Place {
  placeId: string;
  description: string;
  structuredFormat: {
    mainText: {
      text: string
    };
    secondaryText: {
      text: string
    };
  };
}

class Place implements Place {
  placeId: string;
  description: string;
  structuredFormat: {
    mainText: {
      text: string
    };
    secondaryText: {
      text: string
    };
  };

  constructor(place: any) {
    this.placeId = place.placeId || place.place_id;
    this.description = place.description || (place.structuredFormat.mainText?.text || place.structuredFormatting.main_text) + ', ' + (place.structuredFormat.secondaryText?.text || place.structuredFormatting.secondary_text);
    this.structuredFormat = {
      mainText: {
        text: place.structuredFormat.mainText?.text || place.structuredFormatting.main_text
      },
      secondaryText: {
        text: place.structuredFormat.secondaryText?.text || place.structuredFormatting.secondary_text
      }
    };
  }
}

interface PlaceResponse {
  structuredFormat: {
    mainText: string;
    secondaryText: string;
  };
  structuredFormatting?: {
    main_text?: string;
    secondary_text?: string;
  };
  placeId: string;
  description: string;
  googleMapsUri: string;
}

interface PlaceSearchProps {
  location: string | PlaceResponse;
  onPlaceSelect: (place: Place) => void;
  enabled: boolean;
}

const GOOGLE_PLACES_API_KEY = 'AIzaSyA3yMfimPhBz6gxzKXQViCn5YK-IvASFrs';

export function PlaceSearch({ location, onPlaceSelect, enabled }: PlaceSearchProps) {

  const [input, setInput] = useState('');
  const [debouncedInput] = useDebounce(input, 500);
  const [predictions, setPredictions] = useState<Place[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState<Place | null>(null);
  // const [isInitialized, setIsInitialized] = useState(false);  
  const locationString = location ? (typeof location === 'string' ? location : location?.description) : '';
  const { data: autoCompleteResult, refetch: doAutocomplete, isError, isFetching, isSuccess } = QuerySrvc.QUERIES.GOOGLE.AUTOCOMPLETE(
    debouncedInput,
    false
  );


  useEffect(() => {
    if (Boolean(debouncedInput && selectedPlace?.description !== debouncedInput && locationString !== debouncedInput)) {
      doAutocomplete();
    }
  } , [debouncedInput]);

  useEffect(() => {
    if (location) {
      setInput(typeof location === 'string' ? location : location.description || '');
    } else {
      setInput('');
      setPredictions([]);
    }
  }, [location]);

  useEffect(() => {
    if (selectedPlace) {
      onPlaceSelect(selectedPlace)
    }

  }, [selectedPlace]);

  useEffect(() => {
    if (isFetching) {
      setLoading(true);
    } else {
      setLoading(false);
    }
    if (isSuccess && autoCompleteResult) {
      setPredictions(autoCompleteResult?.suggestions?.map((v: any) => v.placePrediction));
      setLoading(false);
    }
  }, [isFetching]);

  const handleSelect = (place: Place) => {
    const placeObj = new Place(place);
    setSelectedPlace(placeObj);
    setInput(placeObj.description);
    setPredictions([]);
  };


  return (
    <div className="w-full max-w-md">
      <div className="form-control w-full">
        <div className="relative">
          <div className="relative w-full">
            {
              predictions.length > 0 && (
                <ul className="menu flex-nowrap custom-scroll bg-brand-darkgray w-full rounded-box shadow-lg max-h-[300px] overflow-y-auto absolute bottom-full mb-2">
                  {predictions.map((place) => (
                    <li key={place.placeId}>
                      <button
                        onClick={() => handleSelect(place)}
                        className="flex items-start p-4 hover:bg-brand-fadegray"
                      >
                        <MapPin className="h-5 w-5 mt-1 mr-2 flex-shrink-0" />
                        <div>
                          <div className="font-medium">
                            {place.structuredFormat?.mainText?.text}
                          </div>
                          <div className="text-sm text-base-content/70">
                            {place.structuredFormat?.secondaryText?.text}
                          </div>
                        </div>
                      </button>
                    </li>
                  ))}
                </ul>
              )
            }

            <input
              disabled={!enabled}
              type="text"
              placeholder="Add a location..."
              className={`input bg-transparent rounded-none focus:outline-none border-transparent	border-transparent focus:border-b-brand-fadegray border-y-2 border-0 w-full p-0
                disabled:cursor-default disabled:bg-transparent disabled:border-0 `}
              value={input}
              onChange={(e) => {
                setInput(e.target.value)
              }}
            />
            {loading && (
              <div className="absolute right-3 top-3">
                <Loader2 className="animate-spin h-6 w-6 text-gray-400" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}