import React from "react";
import { Helmet } from "react-helmet";
interface MetaTagsProps {
  title: string;
  description: string;
  url: string;
  image: string;
}

const MetaTags: React.FC<MetaTagsProps> = ({ title, description, url, image }) => {
  return (
    <Helmet>
      {/* HTML Meta Tags */}
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta name="description" content={description} />
      <meta property="og:site_name" content={title} />
      <meta name="keywords" content="Travel, Events, MICE, Meetup, GetTogether. Create, Share, Engage!, Itinerary,  DMC, Corporate Travel, FIT, Free Independent Traveler" />
      <meta name="robots" content="index, follow" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="language" content="English" />
      <meta name="revisit-after" content="1 days" />
      
      {/* Facebook Meta Tags */}
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content={new URL(url).hostname} />
      <meta property="twitter:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  );
};

export default MetaTags;