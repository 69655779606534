import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import HttpSrvc from '../HttpSrvc';
import { delay } from '../utils';
import { RSVP_CONSTANTS } from './RsvpApi';
import { SOURCE } from '../../config/constants';

export const QUERIES = {
  EVENTS_GET: () => useQuery({
    queryKey: [EVENT_CONSTANTS.QUERY_KEYS.EVENTS],
    queryFn: () => {
      return HttpSrvc.get('/api/events');
    }
  }),
  EVENT_GET_BY_ID: (eventId?: string) => useQuery({
    queryKey: [EVENT_CONSTANTS.QUERY_KEYS.EVENTS, eventId],
    queryFn: () => {
      return HttpSrvc.get(`/api/events/${eventId}`);
    },
    staleTime: 2000,
    enabled: !!eventId
  })
};

export const MUTATIONS = {
  CREATE_EVENT: (queryClient?: QueryClient) => useMutation({
    mutationFn: (formData: FormData) => {
      return HttpSrvc.postFormData('/api/events', formData);
    },
    onSuccess: () => {
      queryClient?.invalidateQueries({ queryKey: [EVENT_CONSTANTS.QUERY_KEYS.EVENTS] });
    }
  }),

  UPDATE_EVENT: (queryClient?: QueryClient) => useMutation({
    mutationFn: ({ eventId, payload }: { eventId: string; payload: any }) => {
      return HttpSrvc.put(`/api/events/${eventId}`, payload);
    },
    onSuccess: (data: any) => {
      queryClient?.invalidateQueries({ queryKey: [EVENT_CONSTANTS.QUERY_KEYS.EVENTS] });
    }
  }),

  UPLOAD_EVENT_IMAGE: (queryClient?: QueryClient) => useMutation({
    mutationFn: async ({ eventId, file }: { eventId: string; file: File }) => {
      const formData = new FormData();
      formData.append('file', file);
      const response = await HttpSrvc.postFormData(`/api/events/${eventId}/image`, formData);
      await delay(500); // adding delay so that CDN is ready to show the images.
      return response;
    },
    onSuccess: async (data: any) => {
      queryClient?.invalidateQueries({ queryKey: [EVENT_CONSTANTS.QUERY_KEYS.EVENTS] });
    }
  }),

  UPLOAD_EXTERNAL_IMAGE: (queryClient?: QueryClient) => useMutation({
    mutationFn: async ({ eventId, photoId, source }: {
      eventId: string;
      photoId: string,
      source: SOURCE
    }) => {
      return HttpSrvc.post(`/api/events/${eventId}/image/external`, { photoId, source });
    },
    onSuccess: async (data: any) => {
      queryClient?.invalidateQueries({ queryKey: [EVENT_CONSTANTS.QUERY_KEYS.EVENTS] });
    }
  }),

  DELETE_EVENT: (queryClient?: QueryClient) => useMutation({
    mutationFn: (eventId: string) => {
      return HttpSrvc.delete(`/api/events/${eventId}`);
    },
    onSuccess: (data: any) => {
      queryClient?.invalidateQueries({ queryKey: [EVENT_CONSTANTS.QUERY_KEYS.EVENTS] });
    }
  }),

  CLONE_EVENT: (queryClient?: QueryClient) => useMutation({
    mutationFn: ({ eventId, startDateTime, privacy }: any) => {
      return HttpSrvc.post(`/api/events/${eventId}/clone`, { startDateTime, privacy });
    },
    onSuccess: (data: any) => {
      queryClient?.invalidateQueries({ queryKey: [EVENT_CONSTANTS.QUERY_KEYS.EVENTS] });
    }
  }),

  REMOVE_GUEST: (queryClient?: QueryClient) => useMutation({
    mutationFn: ({ eventId, guestIds }: { eventId: string; guestIds: string[] }) => {
      return HttpSrvc.delete(`/api/events/${eventId}/guests`, { users: guestIds });
    },
    onSuccess: (response, variables) => {
      queryClient?.invalidateQueries({ queryKey: [RSVP_CONSTANTS.QUERY_KEYS.EVENT_RSVP_STATUS, variables.eventId] });
    }
  }),

  MAKE_HOST: (queryClient?: QueryClient) => useMutation({
    mutationFn: ({ eventId, users }: { eventId: string; users: string[] }) => {
      return HttpSrvc.post(`/api/events/${eventId}/hosts`, { users});
    },
    onSuccess: (response, variables) => {
      queryClient?.invalidateQueries({ queryKey: [RSVP_CONSTANTS.QUERY_KEYS.EVENT_RSVP_STATUS, variables.eventId] });
    }
  }),

  REMOVE_HOST: (queryClient?: QueryClient) => useMutation({
    mutationFn: ({ eventId, users }: { eventId: string; users: string[] }) => {
      return HttpSrvc.delete(`/api/events/${eventId}/hosts`, { users });
    },
    onSuccess: (response, variables) => {
      queryClient?.invalidateQueries({ queryKey: [RSVP_CONSTANTS.QUERY_KEYS.EVENT_RSVP_STATUS, variables.eventId] });
    }
  })
};

export const EVENT_CONSTANTS = {
  QUERY_KEYS: {
    EVENTS: 'EVENTS'
  }
}