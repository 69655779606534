import React, { useEffect, useState } from 'react';
import { Heart, MessageCircle, Share2 } from 'lucide-react';
import { CommentList } from './CommentList';
import { CommentInput } from './CommentInput';
import { formatDate } from '../../utils/date';
import { Feed } from './types';
import moment from 'moment';
import { FeedMenu } from './FeedMenu';
import QuerySrvc from '../../services/queries/QuerySrvc';
import { useQueryClient } from '@tanstack/react-query';
import LinkifyText from '../LinkifyText';

interface FeedItemProps {
  feed: Feed;
  onLike: (feedId: string, like: boolean) => void;
  onComment: (feedId: string, comment: string) => void;
  onDelete: (feedId: string) => void;
}

export default function FeedCard({ feed, onLike, onComment, onDelete }: FeedItemProps) {
  // const [isCommenting, setIsCommenting] = useState(false);
  const queryClient = useQueryClient();

  const [showAllComments, setShowAllComments] = useState(false);

  const { data: comments, isFetching: isFetchingComments, isSuccess: isCommentFetchSuccess,
    isError: isCommentFetchError, refetch: commentsRefetchForFeed } = QuerySrvc.QUERIES.FEED.COMMENTS.GET(feed.eventId, feed.id, showAllComments, queryClient);


  const aspectRatio = `aspect-[${feed.mediaMetadata?.width}/${feed.mediaMetadata?.height}]`;
  const handleAddComment = (content: string) => {
    onComment(feed.id, content);
  };

  useEffect(() => {
    if (isCommentFetchSuccess) {
      setShowAllComments(true);
    } else {
      setShowAllComments(false);
    }
  }, [isFetchingComments]);

  const mediaType = feed?.mimeType?.split('/')[0];

  return (
    <div className="flex flex-col bg-[#292a2a] rounded-lg shadow mb-4 overflow-hidden border border-[#6B6C6E]/20">
      <div className=" flex flex-1 flex-col p-4">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center">
            <div className="flex flex-1 w-8 h-8 rounded-full overflow-hidden flex-shrink-0">
              {
                feed?.owner?.profilePic && <div className='avatar flex flex-1 btn-circle btn border-0 bg-transparent overflow-hidden'>
                  <div className="absolute top-0 w-full">
                    <img src={feed?.owner?.profilePic} className="w-full" />
                  </div>
                </div> ||
                <div className="avatar placeholder">
                  <div className="bg-neutral text-neutral-content w-8 rounded-full">
                    <span className="text-md">{feed?.owner?.firstName?.[0]?.toUpperCase() || "CP"}</span>
                  </div>
                </div>
              }
            </div>
            <div className="ml-3">
              <div className="font-semibold text-white">
                {feed.owner.firstName} {feed.owner.lastName}
              </div>
              <div className="text-[#6B6C6E] text-sm">
                {moment(feed.createdAt).fromNow()}
              </div>
            </div>
          </div>

          <FeedMenu feed={feed} onDelete={() => onDelete(feed.id)} />
        </div>

        <p className="text-white/90 mb-4 whitespace-pre-line overflow-wrap-anywhere">
          <LinkifyText text={feed.title} />
        </p>

        {feed.media && (
          <div className={`rounded-lg overflow-hidden mb-4 ${aspectRatio}`}>
            {mediaType === 'image' ? (
              <img
                src={feed.media}
                className="w-full h-full object-cover"
              />
            ) : mediaType === 'video' ? (
              <video
                src={feed.media}
                controls
                className="w-full aspect-video h-full object-contain bg-brand-darkgray"
              />
            ) : null}
          </div>
        )}

        <div className="flex items-center gap-6 mt-4 pt-4 border-t border-[#6B6C6E]/20">
          <button
            onClick={() => onLike(feed.id, !feed.selfLike)}
            className={`flex items-center gap-2 ${feed.selfLike ? 'text-brand-lightgray' : 'text-white/80 hover:text-[#93c5fd]'
              }`}
          >
            <Heart className={`w-5 h-5 ${feed.selfLike ? 'text-brand-danger' : 'text-brand-lightgray'}`} fill={feed.selfLike ? 'currentColor' : 'none'} />
            {feed.likesCount > 0 && feed.likesCount}
          </button>
          <button
            onClick={() => { }}
            className="flex items-center gap-2 text-white/80 hover:text-[#93c5fd]"
          >
            <MessageCircle className="w-5 h-5" />
            {(feed?.commentCount && feed?.commentCount > 0) ? feed.commentCount : null}
          </button>
          {/* <button className="flex items-center gap-2 text-white/80 hover:text-[#93c5fd]">
            <Share2 className="w-5 h-5" />
          </button> */}
        </div>


        <div className="mt-4 border-t border-[#6B6C6E]/20 pt-4">
          {
            !showAllComments && <>
              {
                (feed.lastThreeComments.length > 0) && (<CommentList comments={feed.lastThreeComments} />) || null
              }
              {
                (feed?.commentCount && feed?.commentCount > 3) && (
                  <button
                    onClick={() => commentsRefetchForFeed()}
                    className="text-[#6B6C6E] text-sm mt-4"
                  >
                    View all {feed.commentCount} comments
                  </button>
                )
              }
            </> || <CommentList comments={comments?.comments || []} />
          }
          <CommentInput onSubmit={handleAddComment} />
        </div>

      </div>
    </div>
  );
}