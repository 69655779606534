import React, { useContext, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useDropzone } from 'react-dropzone';
import { Image, Send, Loader2, X, Trash2 } from 'lucide-react';
import { EventViewMode } from './EventView';
import QuerySrvc from '../../services/queries/QuerySrvc';
import moment from 'moment';
import { getErrorStringFromObject, getFileAsFormData } from '../../services/utils';
import { ToastContext } from '../Contexts';
import { ImagePreview } from './ImagePreview';
import { useParams } from 'react-router-dom';

interface BulletinViewProps {
  eventId?: string;
  mode?: EventViewMode
}

export function BulletinView({ mode }: BulletinViewProps) {
  const queryClient = useQueryClient();
  const toast = useContext(ToastContext);
  const { eventId } = useParams<{ eventId: string }>();
  const { data: event } = QuerySrvc.QUERIES.EVENT.EVENT_GET_BY_ID(eventId!) as any;
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.gif']
    },
    maxFiles: 1,
    onDrop: (acceptedFiles: React.SetStateAction<File | null>[]) => {
      setSelectedFile(acceptedFiles[0]);
    }
  });

  const { data: bulletins, isLoading } = QuerySrvc.QUERIES.BULLETIN.GET_BULLETINS_FOR_EVENT(eventId!) as any;
  const addBulletinMutation = QuerySrvc.MUTATIONS.BULLETIN.POST(queryClient);
  const deleteBulletinMutation = QuerySrvc.MUTATIONS.BULLETIN.DELETE(queryClient);

  useEffect(() => {
    if (addBulletinMutation.status === 'success') {
      setTitle('')
      setDescription('')
      setSelectedFile(null)
      setIsSubmitting(false);
      toast?.showSuccessToast('Bulletin posted successfully');
    }
    if (addBulletinMutation.status === 'error') {
      setIsSubmitting(false);
      toast?.showErrorToast(getErrorStringFromObject(addBulletinMutation.error) || 'Error posting bulletin');
    }
  }, [addBulletinMutation.status]);

  const ondeleteBulletin = (bulletin: any) => {
    !deleteBulletinMutation.isPending && deleteBulletinMutation.mutate({ eventId: bulletin.eventId, bulletinId: bulletin.id });
  }

  const submitBulletin = () => {
    try {
      if (isSubmitting) {
        return;
      }

      setIsSubmitting(true);

      if (!title) {
        setIsSubmitting(false);
        return;
      }
      if (!description) {
        setIsSubmitting(false);
        return;
      }

      let formData = null;
      if (selectedFile) {
        formData = getFileAsFormData(selectedFile);
      } else {
        formData = new FormData();
      }

      formData.append('title', title);
      formData.append('description', description);
      addBulletinMutation.mutate({ formData, eventId });
    } catch (error: any) {
      setIsSubmitting(false);
    }

  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    submitBulletin();
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[200px]">
        <Loader2 className="w-8 h-8 animate-spin text-gray-500" />
      </div>
    );
  }

  return (
    <div className="max-w-3xl mx-auto p-4 space-y-6">
      {/* Create Bulletin Form */}
      {
        (event?.isHost && mode === EventViewMode.MANAGE) &&
        <form onSubmit={handleSubmit} className="bg-brand-fadegray rounded-lg shadow-md p-6">
          <h2 className="text-xl text-brand-lightgray font-semibold mb-4">Create New Bulletin</h2>
          <div className="space-y-4">
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Bulletin Title"
              className="w-full p-3 bg-brand-darkgray border border-[#6B6C6E]/20 rounded-lg resize-none text-white placeholder-[#6B6C6E] focus:outline-none focus:ring-2 focus:ring-[#93c5fd]"
              required
            />

            <textarea
              value={description}
              rows={3}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Bulletin Description"
              className="w-full p-3 bg-brand-darkgray border border-[#6B6C6E]/20 rounded-lg resize-none text-white placeholder-[#6B6C6E] focus:outline-none focus:ring-2 focus:ring-[#93c5fd]"
              required
            />

            <div
              {...getRootProps()}
              className={`border-2 border-dashed rounded-lg p-6 cursor-pointer transition-colors
              ${isDragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300 hover:border-gray-400'}`}
            >
              <input {...getInputProps()} />
              <div className="flex flex-col items-center space-y-2">
                {!selectedFile && <Image className="w-8 h-8 text-gray-400" />}
                {selectedFile && <ImagePreview file={selectedFile} onRemove={() => { setSelectedFile(null); }} />}
                {selectedFile ? (
                  <div className="flex items-center space-x-2">
                    <span className="text-sm text-gray-600">{selectedFile.name}</span>
                    <button
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedFile(null);
                      }}
                      className="text-red-500 hover:text-red-700"
                    >
                      <X className="w-4 h-4" />
                    </button>
                  </div>
                ) : (
                  <p className="text-sm text-gray-500">
                    {isDragActive ? 'Drop the image here' : 'Drag & drop an image here, or click to select'}
                  </p>
                )}
              </div>
            </div>

            <button
              type="submit"
              disabled={addBulletinMutation.isPending}
              className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center space-x-2"
            >
              {addBulletinMutation.isPending ? (
                <>
                  <Loader2 className="w-4 h-4 animate-spin" />
                  <span>Posting...</span>
                </>
              ) : (
                <>
                  <Send className="w-4 h-4" />
                  <span>Post Bulletin</span>
                </>
              )}
            </button>
          </div>
        </form>
      }
      {/* Bulletins List */}
      <div className="space-y-4">
        {/* <h2 className="text-xl font-semibold">Recent Bulletins</h2> */}
        {
        bulletins.length > 0 ? bulletins?.map((bulletin: any) => (
          <div key={bulletin.id} className="bg-brand-fadegray rounded-lg shadow-md p-6">

            <div className="flex justify-between items-start mb-2">
              <div className="flex flex-col">
                <h3 className="text-lg font-semibold text-brand-lightgray">{bulletin.title}</h3>
                <span className="text-sm text-brand-lightgray">
                  {moment(bulletin.timestamp).fromNow()}
                </span>
              </div>
              {
                (event?.isHost && mode === EventViewMode.MANAGE) &&
                <div className="flex justify-end items-end z-50">
                  <button
                    onClick={() => {
                      if (window.confirm('Are you sure you want to delete this bulletin?')) {
                        ondeleteBulletin(bulletin)
                      }
                    }}
                    className="hover:bg-brand-darkgray/50 text-brand-danger text-sm rounded"
                    title='Delete' >
                    <Trash2 size={18} />
                  </button>
                </div>
              }
            </div>
            {bulletin.media && (
              <img
                src={bulletin.media}
                className="mt-4 rounded-lg max-h-96 w-full object-cover"
              />
            )}
            <p className="text-brand-lightgray whitespace-pre-wrap">{bulletin.description}</p>

          </div>
        )) : 
        <div className="mt-10 pt-10">
          <h3 className="text-lg text-center font-semibold text-brand-lightgray">No bulletins posted</h3>
        </div>
      }
      </div>
    </div>
  );
}