import React, { useCallback, useContext, useEffect, useRef } from "react";
import { Camera, EllipsisVertical, Image } from 'lucide-react';
import { useNavigate } from "react-router-dom";
import { getProfilePic, getUserNameOrEmail } from "../../services/utils";
import { formatDate } from "../../utils/date";
import { ToastContext } from "../Contexts";
import QuerySrvc from "../../services/queries/QuerySrvc";
import { useQueryClient } from "@tanstack/react-query";
import { FeedMenu } from "../events/FeedMenu";
import { EventOptionsMenu } from "../events/EventOptionsMenu";
import ImageSearch from "../itinerary/ImageSearch";
import { ASPECT_RATIO, SOURCE } from "../../config/constants";
import ModalWrapper from "../Modal";
import SaveAnimation from "../SaveAnimation";

export enum EventCardType {
  DEFAULT = 'DEFAULT',
  HOME = 'HOME',
  MANAGE = 'MANAGE'
};

const EventCard = ({ event, onEventClick, cardType }: { event: any, onEventClick?: (arg: any) => void, cardType: EventCardType }) => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const modalRef = useRef<any>(null);

  const updateEventMutation = QuerySrvc.MUTATIONS.EVENT.UPDATE_EVENT(queryClient);
  const uploadImageMutation = QuerySrvc.MUTATIONS.EVENT.UPLOAD_EVENT_IMAGE(queryClient);
  const uploadExternalImageMutation = QuerySrvc.MUTATIONS.EVENT.UPLOAD_EXTERNAL_IMAGE(queryClient);
  const isHost = event?.isOwner || event?.isHost;

  const [showImageSearch, setShowImageSearch] = React.useState(false);

  const toast = useContext(ToastContext);

  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (showImageSearch) {
      modalRef.current?.openModal();
    } else {
      modalRef.current?.closeModal();
    }
  }, [showImageSearch]);

  useEffect(() => {
    modalRef?.current?.setLoadingState(uploadImageMutation.status);
    if (uploadImageMutation.status === 'success') {
      uploadImageMutation.reset();
    } else if (uploadImageMutation.status === 'error') {
      toast?.showErrorToast('Failed to upload image');
    }
  }, [uploadImageMutation.status]);

  useEffect(() => {
    modalRef?.current?.setLoadingState(uploadExternalImageMutation.status);
    if (uploadExternalImageMutation.status === 'success') {
      // toast?.showSuccessToast('Image uploaded successfully');
      uploadExternalImageMutation.reset();
    } else if (uploadExternalImageMutation.status === 'error') {
      toast?.showErrorToast('Failed to upload image');
    }
  }, [uploadExternalImageMutation.status]);


  const handleEventClick = (event: any) => {
    navigate(`/events/${event.id}`);
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>, eventId: string) => {
    const file = e.target.files?.[0];
    if (!file || !eventId) {
      return;
    }
    uploadImageMutation.mutate({
      eventId,
      file
    });
    fileInputRef.current && (fileInputRef.current.value = '');
  };

  const profilePic = getProfilePic(event.owner?.profilePic);
  const username = getUserNameOrEmail(event.owner);

  const triggerFileInput = (e: React.MouseEvent, dayId: string) => {
    e.stopPropagation();
    fileInputRef?.current?.click();
  };

  const updateEvent = (update: any) => {
    if (!event.id) return;
    updateEventMutation.mutate({
      eventId: event.id,
      payload: update
    });
  };

  const getFormattedEventDate = useCallback(() => {
    const startDate = formatDate(event.startTime, 'MMM DD');
    const endDate = formatDate(event.endTime, 'MMM DD, YYYY');
    return `${startDate} - ${endDate}`;
  }, [event.startTime, event.endTime]);

  const handleExternalImageUpload = (image: any) => {

    if (uploadExternalImageMutation.isPending) {
      return;
    }

    const photoId = image.photoId;
    uploadExternalImageMutation.mutate({
      eventId: event.id,
      photoId,
      source: image.source
    });
  };

  return (
    <div
      key={event.id}
      onClick={() => onEventClick ? onEventClick(event) : handleEventClick(event)}
      className="bg-brand-darkgray overflow-hidden
              
              w-full max-w-[470px] cursor-pointer
              border-b border-brand-fadegray pb-6 mb-6
              md:border-b-0 md:pb-0 md:mb-0" >
      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        accept="image/*"
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => {
          e.stopPropagation();
          handleImageUpload(e, event.id)
        }
        }
      />
      {
        cardType === EventCardType.HOME &&
        <div className="flex items-center mb-3">
          <div className="py-4 flex items-center space-x-4">
            <div className="flex flex-1 w-8 h-8 rounded-full overflow-hidden flex-shrink-0">
              {
                profilePic && <div className='avatar flex flex-1 btn-circle btn border-0 bg-transparent overflow-hidden'>
                  <div className="absolute top-0 w-full">
                    <img src={profilePic} className="w-full" />
                  </div>
                </div> ||
                <div className="avatar placeholder">
                  <div className="bg-neutral text-neutral-content w-8 rounded-full">
                    <span className="text-md">{username?.[0]?.toUpperCase() || "CP"}</span>
                  </div>
                </div>
              }
            </div>
            <div className="min-w-0">
              <h3 className="text-brand-lightgray font-semibold truncate">
                {
                  username || "App User"
                }
              </h3>
              <p className="text-brand-lightgray text-sm">
                {formatDate(event.startTime, 'MMM DD, YYYY')}
              </p>
            </div>
          </div>
        </div>
      }
      <div className={`${cardType === EventCardType.MANAGE ? 'aspect-square' : 'aspect-square'} flex relative w-full ${event.image ? 'items-end justify-end' : 'items-center justify-center'}
          overflow-hidden border border-brand-fadegray rounded bg-gradient-to-r from-brand-darkgray/0 to-brand-fadegray/50`}>
        {
          event.image && <img
            src={event.image}
            className="absolute w-full h-full object-cover transform transition-transform duration-300 hover:scale-105"
          />
        }
        {
          cardType === EventCardType.MANAGE && isHost &&
          <EventOptionsMenu event={event} className={`top-2 right-2`} />
        }

        {
          cardType === EventCardType.MANAGE && isHost &&
          (
            <>
              <div className="w-full h-full bg-gradient-to-r from-brand-darkgray/0 via-brand-lightgray/30 to-brand-darkgray/0 flex items-center justify-center">
                <SaveAnimation start={uploadImageMutation.status === 'pending' || uploadExternalImageMutation.status === 'pending'}
                  success={uploadImageMutation.status === 'success' || uploadExternalImageMutation.status === 'success'}
                  error={uploadImageMutation.status === 'error' || uploadExternalImageMutation.status === 'error'} />
              </div>
              {
                uploadExternalImageMutation.status !== 'pending' && uploadImageMutation.status !== 'pending' &&
                uploadExternalImageMutation.status !== 'success' && uploadImageMutation.status !== 'success' &&
                <div className={` ${event.image ? 'absolute bottom-2 right-2 p-2 bg-white rounded-full' : 'flex flex-col absolute items-center hover:drop-shadow-contrast-black'} `}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowImageSearch(true);
                  }}
                >
                  <Camera size={16} className={`cursor-pointer ${event.image ? 'text-brand-darkgray' : 'text-brand-lightgray'}`} />
                  {!event.image && <span className="text-sm cursor-pointer">Add Image</span>}
                </div>
              }
            </>
          ) ||
          <Image size={32} className="self-center mx-auto" />
        }

        {
          cardType === EventCardType.MANAGE && event?.privacy?.toUpperCase() === 'PRIVATE' &&
          <div className="absolute top-2 left-2 p-2 bg-white rounded bg-brand-midgreen text-brand-darkgray text-xs">
            Private
          </div>

        }
      </div>

      <div className="py-4">
        <h3
          onClick={(e) => e.stopPropagation()}
          contentEditable={cardType === EventCardType.MANAGE}
          suppressContentEditableWarning={true}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              e.currentTarget.blur();
            }
          }}
          onBlur={(e) => {
            e.stopPropagation();
            if (cardType !== EventCardType.MANAGE) {
              return;
            }
            const content = e.target.innerText.trim();
            content && content !== event?.name ? updateEvent({ name: content }) : null;
          }}
          className={`text-lg font-semibold text-brand-lightgray mb-2 ${cardType === EventCardType.MANAGE ? 'cursor-text' : 'cursor-default'}`}>
          {event.name}
        </h3>

        {
          cardType === EventCardType.MANAGE &&
          <div className="text-sm text-brand-lightgray">
            {getFormattedEventDate()}
          </div>
        }
      </div>

      <ModalWrapper ref={modalRef}
        modalBoxStyle={'max-w-none h-full xl:w-1/3 xl:justify-self-end'}
        title={'Select Image'}
        onClose={() => setShowImageSearch(false)}>
        {showImageSearch && <ImageSearch
          disabled={uploadImageMutation.status === 'pending' || uploadExternalImageMutation.status === 'pending'}
          aspectRatio={ASPECT_RATIO.SQUARE}
          open={false}
          subtitle={`${event?.name} | ${event?.startTime ? formatDate(event?.startTime, `ddd MMM DD`) : ''}`}
          suggestedSearch={event?.name}
          onFileUpload={(e: any) => {
            e.stopPropagation();
            triggerFileInput(e, event?.id)
          }}
          onImageSelect={(image: any) => handleExternalImageUpload(image)}
        />}
      </ModalWrapper>
    </div>
  )
};

export default EventCard;