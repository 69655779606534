import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef, useCallback } from 'react';
import SaveAnimation from './SaveAnimation';

const ModalWrapper = forwardRef((props: any, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [backdropVisible, setBackdropVisible] = useState(false);
  const [loadingState, setLoadingState] = useState<'pending' | 'success' | 'error' | null>(null);
  const dialogRef = useRef<HTMLDialogElement>(null);

  useImperativeHandle(ref, () => ({
    openModal() {
      setIsOpen(true);
      setBackdropVisible(true);
    },
    closeModal() {
      setIsOpen(false);
      setBackdropVisible(false);
    },
    setLoadingState(state: any) {
      setLoadingState(state);
    }
  }));

  useEffect(() => {
    if (isOpen) {
      dialogRef.current?.showModal();
    } else {
      dialogRef.current?.close();
      props?.onClose && props?.onClose()
      return;
    }
  }, [isOpen]);

  useEffect(() => {
    if (loadingState === 'success') {
      setTimeout(() => {
        setLoadingState(null);
        // setIsOpen(false);
      }, 500);
    } else if (loadingState === 'error') {
      setTimeout(() => {
        setLoadingState(null);
      }, 500);
    }
  }, [loadingState]);

  return (
    <dialog ref={dialogRef} className={`modal fixed bg-color-none transform transition-transform duration-300 ease-in-out  ${isOpen ? 'translate-x-0' : 'hidden delay-100 translate-x-full'}`}
      onClick={(e) => {
        e.stopPropagation();
        // if (loadingState !== 'pending') {
          setIsOpen(false);
          setBackdropVisible(false);
        // }
      }}>
      <div className={`modal-box flex flex-col bg-brand-darkgray overflow-hidden shadow-fadegray ${props.modalBoxStyle}`} onClick={(e) => e.stopPropagation()}>
        <div className='flex flex-row justify-between items-center'>
          {
            props.title && <h2 className="font-bold text-lg text-brand-lightgray">{props.title}</h2>
          }
          <SaveAnimation start={loadingState === 'pending'} success={loadingState === 'success'} error={loadingState === 'error'} />
        </div>
        <div className="flex flex-col py-4 overflow-hidden">
          {props.children}
        </div>
        <div className="flex flex-row modal-action">
          {props.actionButton}
          <button
            disabled={loadingState === 'pending'}
            className="btn btn-brand-darkgray"
            onClick={(e) => {
              e.stopPropagation();
              if (loadingState !== 'pending') {
                setIsOpen(false);
                setBackdropVisible(false);
              }
            }} >
            Close
          </button>
        </div>
      </div>
      <label className={`modal-backdrop ${backdropVisible ? 'bg-brand-lightgray/20 delay-150 duration-300' : 'bg-transparent delay-0 duration-100'} transition-colors ease-in-out`} htmlFor="modal"></label>
    </dialog>
  );
});

export default ModalWrapper;