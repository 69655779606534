import React, { useContext, useEffect, useState } from 'react';
import { AlertTriangle, ChevronDown, File, Upload, X } from 'lucide-react';
import { useQueryClient } from '@tanstack/react-query';
import QuerySrvc from '../../services/queries/QuerySrvc';
import { ToastContext } from '../Contexts';
import { useNavigate, useParams } from 'react-router-dom';
import { RSVP_TYPE } from './form/AddEventForm';
import SaveAnimation from '../SaveAnimation';
import { formatDate } from '../../utils/date';
import DocumentCard from './DocumentCard';
import { EventViewMode } from './EventView';

interface ActivityDocDrawerProps {
  onClose: () => void;
  activityId: string;
  programId: string;
  eventId: string;
  isHost: boolean;
  mode: EventViewMode;
}

const ActivityDocDrawer: React.FC<ActivityDocDrawerProps> = ({ onClose, activityId, programId, eventId, isHost, mode }) => {
  const queryClient = useQueryClient();

  const { data: activity, isLoading: isLoadingActivity } = QuerySrvc.QUERIES.ACTIVITY.GET_BY_ID(eventId, programId, activityId);

  const { data: documents, isLoading: isLoadingDocs } = QuerySrvc.QUERIES.DOCS.GET_EVENT_DOCUMENTS_FOR_USER(eventId!, 'common');

  const uploadDocAndUpdateActivityMutation = QuerySrvc.MUTATIONS.ACTIVITY.UPLOAD_DOC_AND_UPDATE_ACTIVITY(queryClient);

  const updateActivityMutation = QuerySrvc.MUTATIONS.ACTIVITY.UPDATE_ACTIVITY(queryClient);

  const dropdownRef = React.useRef<any>(null);

  if (isLoadingActivity) {
    return (
      <div className="h-full p-4 flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-brand-lightgray"></div>
      </div>
    );
  }

  const onDocDelete = (documentId: string) => {
    updateActivityMutation.mutate({
      eventId,
      dayId: activity.programId,
      activityId,
      payload: {
        documents: activity.documents.filter((doc: any) => doc.id !== documentId).map((doc: any) => doc.id)
      }
    });
  }

  return (
    <div className="h-full p-4">
      <button
        onClick={onClose}
        className="absolute right-0 top-2 text-brand-lightgraybackup p-4 hover:text-brand-lightgray/80 transition-colors"
      >
        <X className="w-5 h-5" />
      </button>

      <div className="flex flex-col mb-6">
        <h2 className="flex flex-row text-xl font-semibold text-brand-lightgraybackup">
          <span>Activity Documents</span>
          <SaveAnimation start={updateActivityMutation.isPending} success={updateActivityMutation.isSuccess}
            error={updateActivityMutation.isError} />
          <SaveAnimation start={uploadDocAndUpdateActivityMutation.isPending} success={uploadDocAndUpdateActivityMutation.isSuccess}
            error={uploadDocAndUpdateActivityMutation.isError} />
        </h2>
        <div className="flex flex-row text-md font-normal text-brand-lightgraybackup">
          {`${activity?.name} | ${activity?.startDateTime ? formatDate(activity?.startDateTime, `HH:MM A, DD MMM 'YY`) : ''}`}
        </div>
      </div>

      {
        (isHost && mode === EventViewMode.MANAGE) && <div className="space-y-6 mb-4">
          <button className="dark-btn flex w-full"
            onClick={() => {
              const fileInput = document.createElement('input');
              fileInput.type = 'file';
              fileInput.accept = 'image/jpeg,image/jpg,image/png,application/pdf';
              fileInput.multiple = true;
              fileInput.onchange = (e: any) => {
                const files = Array.from(e.target.files);
                const formData = new FormData();
                files.forEach((file: any) => {
                  if (file) {
                    formData.append('file', file);
                  }
                });

                uploadDocAndUpdateActivityMutation.mutate({
                  eventId,
                  dayId: activity.programId,
                  activityId,
                  existingDocuments: activity.documents.map((doc: any) => doc.id),
                  formData: formData
                });
              };
              fileInput.click();
            }}
          >
            <Upload className='w-4 h-4' /> Upload Docs
          </button>
        </div>
      }


      {
        documents?.length > 0 &&
        <div className="space-y-6">
          {(isHost && mode === EventViewMode.MANAGE) && <div className="dropdown w-full">
            <div tabIndex={0} role="button" className="btn rounded w-full justify-between">
              Select Documents <ChevronDown />
            </div>
            <ul tabIndex={0} className="dropdown-content menu bg-base-100 z-[1] w-full shadow m-1 rounded" ref={dropdownRef}>
              {!isLoadingDocs && documents?.map((doc: any) => (
                <li key={doc.id} onClick={() => {
                  dropdownRef.current.blur();
                  updateActivityMutation.mutate({
                    eventId,
                    dayId: activity.programId,
                    activityId,
                    payload: {
                      documents: Array.from(new Set([...activity.documents.map((d: any) => d.id), doc.id]))
                    }
                  });
                }}>
                  <a>
                    <File className='mr-1' />
                    {doc.otherDocumentType}
                  </a>
                </li>
              ))}
            </ul>
          </div>}
          <div className="grid grid-cols-1 gap-4">
            {activity?.documents?.map((doc: any) => (
              <DocumentCard key={doc.id} document={doc} onDelete={onDocDelete} mode={mode} />
            ))}
          </div>
        </div>
      }
      {
        !(activity?.documents?.length > 0) && <div className="space-y-6">
          <span className='text-brand-lightgraybackup text-sm font-normal flex justify-center gap-2'>
            No documents uploaded yet
          </span>
        </div>
      }
    </div>
  );
};

export default ActivityDocDrawer;