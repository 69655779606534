import React, { useState } from 'react';
import TypeWriter from './components/TypeWriter';
import FeatureSection from './components/features/FeatureSection';
import AppDownloadSection from './components/download/AppDownloadSection';
import Footer from './components/footer/Footer';

interface Props {
  name: string;
}

const App = (props: Props) => {
  const [showSecondTypeWriter, setShowSecondTypeWrite] = useState(false);

  return (
    <div id="body-wrapper" className="flex w-full h-full bg-brand-darkgray text-brand-lightgray flex">
      <div className="w-full h-full flex flex-col overflow-auto custom-scroll">
        <div className="flex flex-col flex-1 w-full p-4 lg:p-0 align-middle justify-center gap-y-12 mx-auto">
          <div className="text-center space-y-8 text-xl flex justify-center">
            <div className='h-24'>
              <TypeWriter 
                text="All things events." 
                speed={32}
                onComplete={() => {}}
                className="font-light"
              />
              <br />
            </div>
            <div>
              <TypeWriter 
                text={"Create,  Share,  Engage."}
                speed={32}
                className="font-normal"
              />
            </div>
          </div>

          <FeatureSection />
        </div>
        <AppDownloadSection />
        <Footer />
      </div>
    </div>
  );
};

export default App;