import React, { useEffect, useState } from 'react';
import { X, Apple, PlayCircle } from 'lucide-react';
import CarbonpaperLogoOnly from "./../images/carbonpaper-logo.svg";
import appStoreLogo from "./../images/ios_app_store.png";
import playStoreLogo from "./../images/android_app_store.png";

export default function AppDownloadBanner({ toggleSwitch, toggleSwitchButton }: { toggleSwitch?: boolean, toggleSwitchButton?: boolean }) {
  const [showBannerOpenButton, setShowBannerOpenButton] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(!!toggleSwitch);
  }, [toggleSwitch])

  useEffect(() => {
    setShowBannerOpenButton(!!toggleSwitchButton);
  }, [toggleSwitchButton])

  return (
    <>
      {/* Trigger Button */}
      {
        showBannerOpenButton && <button
          onClick={() => setIsOpen(true)}
          className={`fixed bottom-4 right-4 bg-brand-lightgray text-brand-darkgray px-6 py-3 rounded-full border-2 border-brand-lightgray
                    hover:bg-brand-darkgray hover:text-brand-lightgray hover:border-1 transition-all md:bottom-6 md:right-6`}>
          Download app
        </button>
      }

      {/* Sliding Banner */}
      <div
        className={`fixed bottom-0 left-0 right-0 bg-brand-darkgray transform transition-transform 
          duration-300 ease-in-out border-t-2 border-brand-fadegray ${isOpen ? 'translate-y-0' : 'translate-y-full'
          }`} >
        <div className="container mx-auto px-4 py-6 md:py-8 ">
          <div className="flex flex-col md:flex-row items-center justify-between  gap-6">
            {/* App Info */}
            <div className="flex items-center gap-6">
              <div className="w-16 h-16 md:w-20 md:h-20 rounded-2xl overflow-hidden flex-shrink-0">
                <img
                  src={CarbonpaperLogoOnly}
                  alt="App Icon"
                  className="w-full h-full object-contain"
                />
              </div>
              <div className="flex-1">
                <h3 className=" sm:text-xl md:text-2xl font-bold text-brand-lightgray">
                  Grab Your CarbonPaper Copy
                </h3>
                <p className="text-brand-lightgray sm:text-md mt-1">
                  Download the app for an indulging experience
                </p>
              </div>
            </div>

            {/* Store Buttons */}
            <div className="flex sm:flex-row gap-3 w-full md:w-auto">
              {/* <a
                href="#app-store"
                className={`flex items-center justify-center gap-2 bg-brand-darkgray
                  text-brand-darkgray px-6 py-3 rounded-lg transition-colors`} > */}
                <a className="m-auto flex items-center justify-center gap-2"   target='_blank'
                  href='https://apps.apple.com/in/app/carbonpaper-app/id6477400555?ign-itscg=30200&ign-itsct=apps_box_promote_link' >
                  <img style={{ height: '50px', margin: 'auto' }} className='w-full h-1/2 flex justify-self-end' src={appStoreLogo} alt="App Store Image" />
                </a>
                {/* <Apple className="w-6 h-6" />
                <div className="text-left">
                  <div className="text-xs">Download on the</div>
                  <div className="text-sm font-semibold">App Store</div>
                </div> */}
              {/* </a> */}
              {/* <a
                href="#play-store"
                className={`flex items-center justify-center gap-2 text-white px-6 py-3 bg-brand-darkgray
                  rounded-lg transition-colors`} > */}
                <a className="m-auto flex items-center justify-center gap-2"  target='_blank'
                  href='https://play.google.com/store/apps/details?id=com.carbonpaper.app&pcampaignid=web_share&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                  <img style={{ height: '50px', margin: 'auto' }} className='w-full h-1/2 flex justify-self-end'
                    src={playStoreLogo} alt="Google Play Store Image" />
                </a>
                {/* <PlayCircle className="w-6 h-6" />
                <div className="text-left">
                  <div className="text-xs">Get it on</div>
                  <div className="text-sm font-semibold">Google Play</div>
                </div> */}
              {/* </a> */}
            </div>

            {/* Close Button */}
            <button
              onClick={() => { setShowBannerOpenButton(false); setIsOpen(false) }}
              className="absolute top-2 sm:top-4 right-2 sm:right-4 text-gray-400 hover:text-gray-600 transition-colors"
            >
              <X className="w-6 h-6" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}