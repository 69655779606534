import React, { useMemo } from 'react';
import { Calendar, Users, MessageSquare, FileText, MessageSquareText, Settings, Newspaper } from 'lucide-react';
import { EventViewMode } from './EventView';
import { useParams } from 'react-router-dom';
import QuerySrvc from '../../services/queries/QuerySrvc';

type EventStatus = 'ongoing' | 'upcoming' | 'finished';

interface EventStatusTabsProps {
  activeTab: TabType;
  onTabChange: (tab: TabType) => void;
  counts?: {
    ongoing: number;
    upcoming: number;
    finished: number;
  };
  onFabClick?: () => void;
  fabVisible?: boolean;
  fabPulse?: boolean;
  mode?: EventViewMode;
  onSettingsClick: () => void;
}

export type TabType = 'itinerary' | 'social' | 'attendees' | 'documents' | 'feedback'  | 'bulletin';

export const getEventTabs = (isHost: boolean, mode: EventViewMode): { id: TabType; label: string; icon: any }[] => {
  const tabs = [
    { id: 'itinerary' as TabType, label: 'Itinerary', icon: Calendar },
    { id: 'social' as TabType, label: 'Social', icon: MessageSquare },
    { id: 'attendees' as TabType, label: 'Attendees', icon: Users },
    { id: 'documents' as TabType, label: 'Documents', icon: FileText },
    { id: 'bulletin' as TabType, label: 'Bulletin', icon: Newspaper },
    ...(isHost && mode === EventViewMode.MANAGE ? [
      { id: 'feedback' as TabType, label: 'Feedback', icon: MessageSquareText },
    ] : []),
  ];

  return tabs;
};

const EventTabs: React.FC<EventStatusTabsProps> = ({
  activeTab,
  onTabChange,
  fabVisible,
  onFabClick,
  fabPulse,
  mode,
  onSettingsClick
}) => {

  const { eventId } = useParams<{ eventId: string }>();
  const { data: event } = QuerySrvc.QUERIES.EVENT.EVENT_GET_BY_ID(eventId) as any;
  const isHost = event?.isOwner || event?.isHost;

  const tabs = useMemo(() => getEventTabs(isHost, mode!), [isHost, mode]);

  return (
    <div className="flex flex-1 space-x-4 bg-brand-darkgray z-40">
      <div className='flex flex-1'>
        {tabs.map(tab => {
          const Icon = tab.icon;
          return (
            <button
              key={tab.id}
              onClick={() => onTabChange(tab.id)}
              className={`flex flex-col flex-1 sm:flex-none sm:flex-row justify-center items-center items-center sm:px-4 py-2 transition-colors ${activeTab === tab.id
                ? 'text-brand-lightgray border-b'
                : 'text-brand-lightgraybackup hover:text-brand-lightgray hover:bg-brand-lightgray/5'
                }`}
            >
              <Icon className="w-4 h-4 sm:mr-2" />
              <span className="sm:ml-2 bg-brand-fadegray/20 px-2 py-0.5 rounded-full text-xs sm:text-sm sm:flex">
                {tab.label}
              </span>
            </button>
          );
        })}
      </div>
      {
        isHost && mode === EventViewMode.MANAGE && <div className='hidden sm:flex shrink items-center'>
          <Settings className='cursor-pointer hover:text-brand-lightgray' onClick={onSettingsClick} />
        </div>
      }
    </div>
  );
};

export default EventTabs;