import React from 'react';
import {
  Home as HomeIcon,
  Calendar,
  Users,
  Settings,
  Kanban,
  BarChart3,
} from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';

interface DrawerContext {
  isDrawerOpen: boolean;
  setIsDrawerOpen: (isOpen: boolean) => void;
}

const Drawer = ({isDrawerOpen, setIsDrawerOpen}: DrawerContext) => {
  const navigate = useNavigate();
  const location = useLocation();

  const menuItems = [
    { icon: <HomeIcon size={24} />, label: 'Home', path: '/' },
    { 
      icon: <Kanban size={24} />, 
      label: 'Manage', 
      path: '/manage/events',
      subMenu: [
        { id: 'events', label: 'Events', icon: Calendar, path: '/manage/events' },
        // { id: 'teams', label: 'Teams', icon: Users, path: '/manage/teams' },
        // { id: 'crm', label: 'CRM', icon: BarChart3, path: '/manage/crm' }
      ]
    },
  ];

  const handleNavigation = (path: string) => {
    navigate(path);
    setIsDrawerOpen(false);
  };

  return (
    <>
      {/* Overlay */}
      {isDrawerOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-0 z-40"
          onClick={() => setIsDrawerOpen(false)}
        />
      )}

      {/* Drawer */}
      <div
        className={`
          fixed inset-y-0 left-0 z-40
          w-64 bg-brand-darkgray border-r border-brand-fadegray
          transform transition-transform duration-300 ease-in-out
          ${isDrawerOpen ? 'translate-x-0' : '-translate-x-full'}
        `}
      >
        <div className="p-4 h-16"></div>

        <nav className="mt-8">
          {menuItems.map((item, index) => (
            <div key={index}>
              <button
                onClick={() => handleNavigation(item.path)}
                className={`w-full flex items-center px-4 py-3 text-brand-lightgray hover:bg-brand-lightgray/10 transition-colors
                  ${location.pathname === item.path ? 'bg-brand-lightgray/10' : ''}`}
              >
                {item.icon}
                <span className="ml-4">{item.label}</span>
              </button>
              {item.subMenu && (
                <div className="ml-8 mt-2">
                  {item.subMenu.map((subItem) => (
                    <button
                      key={subItem.id}
                      onClick={() => handleNavigation(subItem.path)}
                      className={`w-full flex items-center px-4 py-2 text-brand-lightgray hover:bg-brand-lightgray/10 transition-colors
                        ${location.pathname === subItem.path ? 'bg-brand-lightgray/10' : ''}`}
                    >
                      <subItem.icon size={20} />
                      <span className="ml-4">{subItem.label}</span>
                    </button>
                  ))}
                </div>
              )}
            </div>
          ))}
        </nav>
      </div>
    </>
  );
};

export default Drawer;