import { QueryClient, useMutation } from '@tanstack/react-query';
import HttpSrvc from '../HttpSrvc';
import { ITINERAY_CONSTANTS } from './ItineraryApi';
import { SOURCE } from '../../config/constants';

export const MUTATIONS = {
  UPDATE_PROGRAM: (queryClient?: QueryClient) => useMutation({
    mutationFn: ({ eventId, dayId, payload }: { eventId: string; dayId: string; payload: any }) => {
      return HttpSrvc.put(`/api/events/${eventId}/programs/${dayId}`, payload);
    },
    onSuccess: (data: any, variables) => {
      queryClient?.invalidateQueries({ queryKey: [ITINERAY_CONSTANTS.QUERY_KEYS.ITINERARY_DATA, variables.eventId] });
    }
  }),

  UPLOAD_PROGRAM_IMAGE: (queryClient?: QueryClient) => useMutation({
    mutationFn: ({ eventId, dayId, file }: { eventId: string; dayId: string; file: File }) => {
      const formData = new FormData();
      formData.append('file', file);
      return HttpSrvc.postFormData(`/api/events/${eventId}/programs/${dayId}/image`, formData);
    },
    onSuccess: (data: any, variables) => {      
      queryClient?.invalidateQueries({ queryKey: [ITINERAY_CONSTANTS.QUERY_KEYS.ITINERARY_DATA, variables.eventId] });
    }
  }),

  UPLOAD_EXTERNAL_IMAGE: (queryClient?: QueryClient) => useMutation({
      mutationFn: ({ eventId, dayId, photoId, source }: {
        eventId: string;
        dayId: string;
        photoId: string,
        source: SOURCE
      }) => {
        return HttpSrvc.post(`/api/events/${eventId}/programs/${dayId}/image/external`, { photoId, source });
      },
      onSuccess: (data: any, variables: any) => {
        queryClient?.invalidateQueries({ queryKey: [ITINERAY_CONSTANTS.QUERY_KEYS.ITINERARY_DATA, variables.eventId] });
      }
    }),
}

export const PROGRAM_CONSTANTS = {
  QUERY_KEYS: {
    PROGRAM: 'PROGRAM'
  }
}