import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import HttpSrvc from '../HttpSrvc';
import CONSTANTS from "./../../config/constants";


export const QUERIES = {
  AUTOCOMPLETE: ( input: string, enabled: boolean ) => useQuery({
    queryKey: [GOOGLE_API_CONSTANTS.QUERY_KEYS.GOOGLE_AUTOCOMPLETE, input],
    queryFn: () => {
      if (!input) return;
      return HttpSrvc.post(GOOGLE_API_CONSTANTS.GOOGLE_AUTOCOMPLETE, { input }, null, {
        'Content-Type': 'application/json',
        'X-Goog-Api-Key': CONSTANTS.ENV_CONSTANTS.GOOGLE_MAPS_KEY
      });
    },
    enabled,
    staleTime: 0    
  })
};

export const MUTATIONS = {
  
};


export const GOOGLE_API_CONSTANTS = {
  QUERY_KEYS: {
    GOOGLE_AUTOCOMPLETE: 'GOOGLE_AUTOCOMPLETE'
  },
  GOOGLE_AUTOCOMPLETE: 'https://places.googleapis.com/v1/places:autocomplete'
};