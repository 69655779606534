import React, { useEffect, useRef, useState } from "react";
import httpSrvc from "../services/HttpSrvc";
import CONSTANTS from "../config/constants";
import { loadScript } from "../services/utils";

interface GoogleSignInProps {
  prompt_parent_id?: string;
  successLoginCallback: (...args: any[]) => void;
  errorCallback: (...args: any[]) => void;
}

export default (props: GoogleSignInProps) => {
  const refEl = useRef<HTMLDivElement | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);

  const handleCredentialResponse = async (credentials: any) => {
    try {
      const response = await httpSrvc.post("/auth/gauth", { credential: credentials.credential });
      return props.successLoginCallback(response);
    } catch (error) {
      console.error("Login Error:", error);
      return props.errorCallback(error);
    }
  };

  const initializeGSI = async () => {
    if (isInitialized) return;

    try {
      await loadScript(CONSTANTS.GOOGLE_SIGNIN_SRC);

      if (!window.google || !window.google.accounts || !window.google.accounts.id) {
        console.error("Google Sign-In SDK not loaded properly");
      }

      window.google?.accounts?.id?.initialize({
        client_id: CONSTANTS.ENV_CONSTANTS.G_CLIENT_ID,
        callback: handleCredentialResponse,
        ux_mode: "popup",
        prompt_parent_id: props.prompt_parent_id,
        itp_support: false,
        cancel_on_tap_outside: false,
      });

      if (refEl.current) {
        window.google?.accounts?.id?.renderButton(refEl.current, {
          type: "standard",
          theme: "outline",
          shape: "pill",
          text: "signin_with",
          logo_alignment: "left",
        });
        setIsInitialized(true);
      }

    } catch (err) {
      console.error("Google Sign-In initialization failed:", err);
      props.errorCallback(err);
    }
  };

  useEffect(() => {
    initializeGSI();

    return () => {
      const scriptTag = document.querySelector(`script[src="${CONSTANTS.GOOGLE_SIGNIN_SRC}"]`);
      if (scriptTag) document.body.removeChild(scriptTag);
      setIsInitialized(false);
    };
  }, []);

  return <div id="googleSignInButtonContainer" className="color-scheme-none flex justify-center" ref={refEl}></div>;
};
