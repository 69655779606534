import React, { useCallback, useEffect } from 'react';
import { useState } from "react";
import { Loader2, CheckCircle } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";

export default function SaveAnimation({ start, success, error }: { start: boolean, success: boolean, error: boolean }) {
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isError, setIsError] = useState(false);


  useEffect(() => {   

    if (start) {
      setIsSaving(true);
      setIsSaved(false);
      setIsError(false);
    }

  }, [start]);

  useEffect(() => {
    if (success || error) {
      setIsSaving(false);
    }

    if (success) {
      setIsSaved(true);
      setTimeout(() => {
        setIsSaved(false);      
      }, 700);
      
    } else if (error) {
      setIsError(true);
      setTimeout(() => {
        setIsSaved(false);      
      }, 500);
    }    
  }, [success, error]);

  return (
    <div className="flex items-center justify-center">
      {/* <button onClick={handleSave} className="px-4 py-2 bg-blue-500 text-white rounded-lg">
        Save
      </button> */}
      <div className="ml-4">
        <AnimatePresence>
          {isSaving && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className={'text-brand-lightgraybackup'}
            >
              <Loader2 className="animate-spin" />
            </motion.div>
          )}
          {isSaved && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="text-brand-midgreen"
            >
              <CheckCircle />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}
