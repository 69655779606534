import moment from 'moment';

export const getDefaultStartTime = (selectedDate: string, isIsoFormat: boolean = false): string => {
  const now = moment.utc();
  const dateTime = moment.utc(selectedDate);

  // Set time to current time
  dateTime.hours(now.hours());
  dateTime.minutes(now.minutes());

  // If time is past 23:00, set to 23:00
  if (dateTime.hours() >= 23) {
    dateTime.hours(23);
    dateTime.minutes(0);
  }

  return isIsoFormat ? dateTime.toISOString() : dateTime.format('HH:mm');
};

export const getDefaultEndTime = (selectedDate: string, isIsoFormat: boolean = false): string => {
  const startDateTime = moment.utc(selectedDate);
  const startTime = getDefaultStartTime(selectedDate);
  const [hours, minutes] = startTime.split(':');

  startDateTime.hours(parseInt(hours));
  startDateTime.minutes(parseInt(minutes));

  // Add one hour
  const endDateTime = startDateTime.clone().add(1, 'hour');

  // If end time would be past 23:00, set to 23:00
  if (endDateTime.hours() >= 23) {
    endDateTime.hours(23);
    endDateTime.minutes(0);
  }
  return isIsoFormat ? endDateTime.toISOString() : endDateTime.format('HH:mm');
};