import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { STORAGE_SRVC_KEYS } from '../config/constants/AppStrings';
import StorageSrvc from '../services/StorageSrvc';

class ApiService {
  private axiosInstance: AxiosInstance;

  constructor(baseURL: string, timeout: number) {
    this.axiosInstance = axios.create({
      baseURL,
      timeout
    });

    this.setupInterceptors();
  }

  private setupInterceptors() {
    this.axiosInstance.interceptors.request.use(
      this.handleRequest,
      this.handleRequestError
    );

    this.axiosInstance.interceptors.response.use(
      this.handleResponse,
      this.handleResponseError
    );
  }

  private async handleRequest(config: InternalAxiosRequestConfig) {
    const authToken = await StorageSrvc.getItem(STORAGE_SRVC_KEYS.AUTH_TOKEN);
    if (authToken && config.headers) {
      config.headers["Authorization"] = `Bearer ${authToken}`;
    }
    return config;
  }

  private handleRequestError(error: any) {
    return Promise.reject(error);
  }

  private handleResponse(response: any) {
    return response;
  }

  private handleResponseError(error: any) {
    if (error?.response?.status === 401) {
      // Handle unauthorized access
    }
    return Promise.reject(error);
  }

  public async get(url: string, config?: any) {
    try {
      const response = await this.axiosInstance.get(url, config);
      return response.data;
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  public async post(url: string, data?: any, config?: any) {
    try {
      const response = await this.axiosInstance.post(url, data, config);
      return response.data;
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  public async put(url: string, data?: any, config?: any) {
    try {
      const response = await this.axiosInstance.put(url, data, config);
      return response.data;
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  public async delete(url: string, config?: any) {
    try {
      const response = await this.axiosInstance.delete(url, config);
      return response.data;
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  private handleError(error: any) {
    if (error.code === "ECONNABORTED") {
      throw new Error("Network Connection Timeout");
    }
  }
}

export default new ApiService(
  ENV_CONSTANTS.BASE_URL,
  ENV_CONSTANTS.HTTP_TIMEOUT
);