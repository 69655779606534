import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useState } from 'react'
import { Calendar, Clock, MapPin, ArrowLeft, Check, Ban, X, CircleCheck, CheckCheck, BadgeCheck, OctagonX, Pencil } from 'lucide-react'
import { DayList } from './DayList';
import { ActivityList } from './ActivityList';
import { InclusionsList } from './InclusionsList';
import moment from 'moment';
import ActivityDetails from './ActivityDetails';
import { Link, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import AuthPanel from '../login/component/AuthPanel';
import QuerySrvc from '../../services/queries/QuerySrvc';
import { ToastHandle } from '../Toast';
import { CurrentUserContext, GlobalContext, ToastContext } from '../Contexts';
import { EventViewMode } from '../events/EventView';
import { getErrorStringFromObject } from '../../services/utils';
import MetaTags from '../MetaTags';
import CONSTANTS from './../../config/constants';

interface ItineraryViewerProps {
  itineraryId?: string;
  mode?: EventViewMode
}

export default function ItineraryViewer({ itineraryId: propItineraryId, mode = EventViewMode.VIEW }: ItineraryViewerProps) {
  const queryClient = useQueryClient();
  const toast = useContext(ToastContext);
  const globalContext = useContext(GlobalContext);
  const { itineraryId: paramItineraryId } = useParams<{ itineraryId: string }>();
  const { currentUser } = useContext(CurrentUserContext);
  const [isHost, setIsHost] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [isGuest, setIsGuest] = useState(false);

  // Use prop itineraryId if provided, otherwise fall back to URL param
  const effectiveItineraryId = propItineraryId || paramItineraryId;

  const { data: event, isSuccess, isLoading, isFetching, error: itineraryError } = QuerySrvc.QUERIES.ITINERARY.GET(effectiveItineraryId);

  const { data: eventUserRsvp, isSuccess: isRsvpStatusFetchSuccess, isError: isRsvpStatusFetchError,
    isFetching: isRsvpStatusFetching, refetch: refetchRsvpStatus } = QuerySrvc.QUERIES.RSVP.GET_RSVP_STATUS_BY_EVENT_ID(effectiveItineraryId!);

  const respondRsvpMutation = QuerySrvc.MUTATIONS.RSVP.RESPOND_RSVP(queryClient);

  const [selectedDayId, setSelectedDayId] = useState(event?.programs?.[0]?.id);
  const selectedDay = event?.programs?.find((day: any) => day.id === selectedDayId);
  const [dayDescriptionExpanded, setDayDescriptionExpanded] = useState(false);

  const [selectedActivityId, setSelectedActivityId] = useState<string | null>(event?.programs?.[0]?.activities?.[0]?.id);
  const selectedActivity = selectedDay?.activities?.find((activity: any) => activity.id === selectedActivityId);

  const [selectedColumn, setSelectedColumn] = useState(0);

  const [openImageUploadPortal, setOpenImageUploadPortal] = useState(false);

  useEffect(() => {
    if (openImageUploadPortal) {
      setOpenImageUploadPortal(false);
    }
  }, [openImageUploadPortal]);

  useEffect(() => {
    if (isSuccess) {
      const isGuest = event?.guests?.findIndex((guest: any) => guest?.guid === currentUser?.guid) !== -1;
      const isHost = event?.hosts?.findIndex((host: any) => host?.guid === currentUser?.guid) !== -1;
      const isOwner = event?.owner?.guid === currentUser?.guid;
      setIsGuest(isGuest);
      setIsHost(isHost);
      setIsOwner(isOwner);
      setSelectedDayId(selectedDayId || event?.programs?.[0]?.id);
      setSelectedActivityId(selectedActivityId || event?.programs?.[0]?.activities?.[0]?.id);
    }
  }, [isFetching]);

  useEffect(() => {
    if (respondRsvpMutation.status === 'success') {
      const onSigninSuccess = async () => {
      }
      onSigninSuccess();
      globalContext.showDownloadAppBar();
    }
    else if (respondRsvpMutation.status === 'error') {
      toast.showErrorToast(respondRsvpMutation?.error?.response?.data?.error);
      globalContext.showDownloadAppBar();
    }
  }, [respondRsvpMutation.status]);

  const isParticipant = isHost || isGuest || isOwner;

  const eventSchedule = useMemo(() => {
    if (!event) return 'loading';

    const now = moment.utc();
    const startDate = moment.utc(event.startTime);
    const endDate = moment.utc(event.endTime);
    if (now.isBefore(startDate)) return 'upcoming';
    if (now.isAfter(endDate)) return 'finished';
    return 'ongoing';
  }, [event]);


  if (isLoading) return <div className='text-center mx-auto mt-4'>Loading...</div>;
  if (itineraryError) return <div className='text-center text-lg mt-8'>{getErrorStringFromObject(itineraryError)}</div>;
  if (!event) return null;

  return (
    <>
      <MetaTags title={CONSTANTS.OG_META.TITLE} description={event?.name} 
        image={event?.image || CONSTANTS.OG_META.IMAGE} url={CONSTANTS.OG_META.URL} />

      <div className="flex flex-1 relative h-full bg-brand-darkgray order-2 max-w-full">
        <div
          className="absolute inset-0 bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${selectedDay?.image || event.image})`,
            backgroundAttachment: 'fixed',
            opacity: 0.3
          }}
        >
          <div className="absolute inset-0 bg-brand-darkgray/70 backdrop-blur-sm"></div>
        </div>

        <div className="relative flex flex-1 flex-col h-full max-w-full">
          {mode === EventViewMode.VIEW &&
            <>
              <header className="flex flex-col sm:flex-row items-center justify-between p-2 sm:p-4 text-brand-lightgray flex z-40">
                {                 
                  <div className='hidden sm:flex flex-1'></div>
                }
                <h1 className="flex flex-1 flex-col justify-center sm:flex-row text-center text-md sm:text-2xl md:text-4xl font-normal ">
                  <span className="sm:grow">
                    {event.name}
                  </span>
                  {
                    isHost &&
                    <Link title='Manage Event'
                      className="flex flex-row self-auto empty-btn cursor-pointer text-brand-lightgray mr-4 hover:bg-brand-lightgray/10 rounded p-2"
                      to={`/manage/events/${eventSchedule}/${event.id}/itinerary`}>
                      <Pencil size={16} strokeWidth={0.75} absoluteStrokeWidth />
                    </Link>
                  }
                  {
                    eventUserRsvp?.status === 'YES' &&
                    <span className="flex flex-row self-end text-brand-midgreen mr-4 mt-4 sm:m-0 py-2 rounded w-28 px-4 border-brand-fadegray border-0 cursor-default relative
                      bg-gradient-to-r to-brand-darkgray via-brand-darkgray from-brand-lightgreen/10 text-sm items-center">
                      <span className='pr-2'>Attending </span>
                      <BadgeCheck className='absolute right-2 ' size={16} color="#1affe8" strokeWidth={0.75} absoluteStrokeWidth />
                    </span>
                  }
                  {
                    eventUserRsvp?.status === 'HOST_APPROVAL_REQUESTED' &&
                    <span className="flex flex-row self-end text-brand-gold mr-4 mt-4 sm:m-0 py-2 rounded w-40 px-4 border-brand-fadegray border-0 cursor-default relative
                      bg-gradient-to-r to-brand-darkgray via-brand-darkgray from-brand-gold/10 text-sm items-center">
                      <span className='pr-2'>Approval Pending</span>
                      <Clock className='absolute right-2 text-brand-gold' size={16} strokeWidth={0.75} absoluteStrokeWidth />
                    </span>
                  }
                  {
                    eventUserRsvp?.status === 'NO' &&
                    <span className="flex flex-row text-brand-danger py-2 rounded w-28 px-4 border-brand-fadegray border-0 cursor-default relative
                      bg-gradient-to-r to-brand-darkgray via-brand-darkgray from-brand-danger/10 text-sm items-center">
                      <span className='pr-2'>Declined</span>
                      <OctagonX className='absolute right-2 ' size={16} color="#ef4444" strokeWidth={0.75} absoluteStrokeWidth />
                    </span>
                  }
                  {
                    eventUserRsvp?.status === 'HOST_REMOVED' &&
                    <span className="flex flex-row self-end text-brand-danger mr-4 mt-4 sm:m-0 py-2 rounded w-36 px-4 border-brand-fadegray border-0 cursor-default relative
                      bg-gradient-to-r to-brand-darkgray via-brand-darkgray from-brand-danger/10 text-sm items-center">
                      <span className='pr-2'>Host Declined</span>
                      <OctagonX className='absolute right-2 ' size={16} color="#ef4444" strokeWidth={0.75} absoluteStrokeWidth />
                    </span>
                  }
                  {
                    eventUserRsvp?.status === 'HOST_APPROVAL_DENIED' &&
                    <span className="flex flex-row self-end text-brand-danger mr-4 mt-4 sm:m-0 py-2 rounded w-36 px-4 border-brand-fadegray border-0 cursor-default relative
                      bg-gradient-to-r to-brand-darkgray via-brand-darkgray from-brand-danger/10 text-sm items-center">
                      <span className='pr-2'>Host Declined</span>
                      <OctagonX className='absolute right-2 ' size={16} color="#ef4444" strokeWidth={0.75} absoluteStrokeWidth />
                    </span>
                  }
                </h1>

                {
                  // eventUserRsvp?.status !== "YES" && eventUserRsvp?.status !== "NO" &&
                  <div className='flex flex-1 flex-row justify-center sm:justify-end gap-4 p-2 sm:p-4 z-40'>
                    {!isParticipant && (!eventUserRsvp || eventUserRsvp?.status === "REQUESTED") &&
                      <>
                        <AuthPanel
                          content="Join"
                          className={`text-brand-lightgray text-sm sm:text-md py-2 rounded w-20 sm:w-28 border-brand-fadegray border-2 hover:shadow-lightgray transition-all
                      bg-gradient-to-r from-brand-darkgray via-brand-darkgray to-brand-lightgreen/10`}
                          callback={() => {
                            respondRsvpMutation.mutate({ eventId: effectiveItineraryId, payload: { status: "Yes" } });
                          }}
                          errorCallback={() => { }}
                        />
                        <AuthPanel
                          content="Decline"
                          className={`text-brand-lightgray text-sm sm:text-md py-2 rounded w-20 sm:w-28 border-brand-fadegray border-2 hover:shadow-fadegray transition-all 
                      bg-gradient-to-r from-brand-darkgray via-brand-darkgray to-brand-danger/10 `}
                          callback={() => {
                            respondRsvpMutation.mutate({ eventId: effectiveItineraryId, payload: { status: "No" } });
                          }}
                          errorCallback={() => { }}
                        />
                      </>
                    }
                  </div>
                }

              </header>

            </>
          }

          <div className="flex-1 flex sm:py-0 overflow-hidden py-2">
            <div className={`w-full sm:mr-3 sm:w-4/12 backdrop-blur-md rounded-2xl overflow-y-hidden sm:flex
             flex-col ${selectedColumn === 0 ? 'flex' : 'hidden'}`}>
              <div className="flex flex-1 overflow-hidden p-0">
                <DayList
                  onElementClick={(day: any) => {
                    setSelectedDayId(day.id);
                    setSelectedActivityId(day.activities?.[0]?.id);
                    setSelectedColumn(1)
                  }}
                  days={event?.programs?.sort((a: any, b: any) => a.dayIndex - b.dayIndex)}
                  selectedDayId={selectedDayId}
                  mode={mode}
                />
              </div>
            </div>
            <div className="hidden sm:flex divider divider-horizontal my-auto mx-0 h-40" />

            <div className={`w-full sm:ml-3 sm:w-4/12  sm:flex flex-col ${selectedColumn === 1 ? 'flex' : 'hidden'} 
                rounded-2xl overflow-hidden` }>
              <h2 className="text-xl font-semibold text-brand-lightgray mb-6 text-center px-4 sm:hidden">
                <ArrowLeft className='absolute left-4 cursor-pointer text-brand-lightgray ' onClick={() => { setSelectedColumn(Math.max(selectedColumn - 1, 0)) }} />
              </h2>
              <div className={`flex flex-1 overflow-y-auto pt-6 pb-4 custom-scroll`}>
                <div className="flex flex-1 flex-col">
                  <ActivityList                    
                    onElementClick={(activity: any, openImageUploadPortal: boolean = false) => {
                      setSelectedColumn(2);
                      setSelectedActivityId(activity.id);
                      setOpenImageUploadPortal(openImageUploadPortal);
                    }}
                    activities={selectedDay?.activities || []}
                    selectedActivityId={selectedActivityId}
                    selectedDay={selectedDay}
                    mode={mode}
                  />
                </div>
              </div>
            </div>
            <div className="hidden sm:flex divider divider-horizontal my-auto mx-0 h-40" />
            <div className={`w-full sm:w-4/12 bg-brand-darkgray/5 rounded-2xl overflow-hidden flex-1 sm:flex flex-col ${selectedColumn === 2 ? 'flex' : 'hidden'}`}>
              <ActivityDetails selectedActivity={selectedActivity} mode={mode} openImageUploadPortal={openImageUploadPortal}/>
              <ArrowLeft className='absolute left-4 cursor-pointer text-brand-lightgray sm:hidden' onClick={() => { setSelectedColumn(Math.max(selectedColumn - 1, 0)) }} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}